import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"subscriptions-page-wrapper fill-height"},[_c('div',{staticClass:"subscriptions-page"},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"top-title-section"},[_c('div',{staticClass:"pricing-intro-title",style:({
						padding: _vm.$vuetify.breakpoint.mdAndDown ? '45px 15px 0 15px' : '35px 15px 0 15px',
						lineHeight: _vm.$vuetify.breakpoint.mdAndDown ? '50px' : '86px',
					})},[_vm._v(" Choose the Plan for Your Needs ")]),_c('div',{staticClass:"pricing-intro-subtitle"},[_vm._v(" Our transparent pricing makes it easy to find a plan that works within your budget and needs. ")]),_c('div',{staticClass:"pricing-month-year",style:({
						margin: _vm.$vuetify.breakpoint.mdAndDown ? '0 0 20px 0' : '0 0 60px 0',
					})},[_c('base-toggle-button',{attrs:{"value":_vm.isMonthly,"large":"","width":"300px"},on:{"input":function($event){_vm.isMonthly = !_vm.isMonthly}},scopedSlots:_vm._u([{key:"option1",fn:function(){return [_vm._v(" Yearly "),_c('span',{staticStyle:{"padding":"0 0 0 5px","font-size":"13px"},style:({ color: _vm.isMonthly ? 'black' : 'white' })},[_vm._v("(20% Off)")])]},proxy:true},{key:"option2",fn:function(){return [_vm._v(" Monthly ")]},proxy:true}])})],1)]),_c('div',{staticClass:"subscriptions-container"},[(_vm.$vuetify.breakpoint.mdAndDown)?[_c('div',{staticClass:"subscription-carousel-container"},[_c('window-stepper',{attrs:{"container-classes":"d-flex","container-styles":{
								maxWidth: '400px',
								justifyContent: 'space-between',
								alignItems: 'center',
								margin: '0 auto 15px',
							},"item-group-styles":{
								padding: '0 20px',
							},"window-view-length":_vm.clubs.length,"largest-window-view":_vm.clubs.length - 1,"continue-disabled":_vm.carouselModel === _vm.clubs.length - 1,"back-disabled":_vm.carouselModel === 0,"show-finish-button":false,"arrows-only":"","allow-non-linear-stepping":""},on:{"on-window-view-back":_vm.prevSlide,"on-window-view-forward":_vm.nextSlide,"step-clicked":_vm.handleStepClick},model:{value:(_vm.carouselModel),callback:function ($$v) {_vm.carouselModel=$$v},expression:"carouselModel"}}),_c(VCarousel,{staticClass:"subscription-carousel",attrs:{"hide-delimiters":"","show-arrows":false,"height":"auto"},model:{value:(_vm.carouselModel),callback:function ($$v) {_vm.carouselModel=$$v},expression:"carouselModel"}},_vm._l((_vm.clubs),function(club){return _c(VCarouselItem,{key:club.key,staticClass:"subscription-carousel-item"},[_c('runnits-subscription-card',{attrs:{"club":club,"is-monthly":_vm.isMonthly},on:{"cta-click":_vm.handleCtaClick}})],1)}),1)],1)]:_c('div',{staticClass:"runnit-subscriptions-container"},_vm._l((_vm.clubs),function(club){return _c('runnits-subscription-card',{key:club.key,style:({
							margin: _vm.clubs[_vm.clubs.length - 1].key === club.key ? '20px 0 0 0' : '20px 20px 0 0',
							...(club.key === _vm.clubs[_vm.clubs.length - 1].key && _vm.$vuetify.breakpoint.width < 1440 ? {
								margin: '20px 0 0 -20px',
							} : {}),
							top: _vm._get(club, 'isBestPlan') ? '-20px' : '0',
						}),attrs:{"club":club,"is-monthly":_vm.isMonthly},on:{"cta-click":_vm.handleCtaClick}})}),1)],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }