import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';
import { db, functions } from '@/firebase';
import _toString from 'lodash/toString';
import _isEmpty from 'lodash/isEmpty';
import _has from 'lodash/has';
import _uniqBy from 'lodash/uniqBy';
import _get from 'lodash/get';
import _trim from 'lodash/trim';
import _sortBy from 'lodash/sortBy';
import _mapKeys from 'lodash/mapKeys';
import {
	Session,
	StableDiffusionOffer,
	StableDiffusionModel,
	User,
	Announcement,
	AppOffer,
	HardwareOffer,
	SoftwareOffer,
	ExtensionOffer,
	AppKey,
	HardwareKey,
	SoftwareKey,
	StripeCustomer,
	SetupSessionPrefill,
	Team,
	BalanceAccount,
	Workspace,
	Region,
	ModelProviderKey,
	SessionOptions,
	CivitaiModelVersion,
	CivitaiModel,
	SessionBackgroundTask,
	Runnit,
	RunnitNode,
	RunnitNodeDef,
	RunnitNodeRun,
	Workshop,
	WorkshopSession,
	WorkshopSessionQueueItem,
	Company,
	LoadedAsset,
	RunnitNodeRunResult,
	RunnitNodeField,
	RunnitUpload,
	RunnitsAccessLevel,
	Avatar,
	ClubOfferBenefits,
} from '@run-diffusion/shared';
import {
	SESSION_STATE,
	SESSION_TYPE,
	STOP_CODE,
} from '@/constants/enums';
import {
	SNACKBAR_STATUS,
	PAST_SESSION_STATES,
} from '@/constants/constants';
import firebase from 'firebase/app';
import Query = firebase.firestore.Query;
import WhereFilterOp = firebase.firestore.WhereFilterOp;
import OrderByDirection = firebase.firestore.OrderByDirection;
import DocumentReference = firebase.firestore.DocumentReference;
import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;
import {
	getCivitaiModelId,
	getCivitaiModelVersionId,
	getSessionSoundsOff,
	nanoIdLowerNum,
	setSessionSoundsOff,
	removeSessionSoundsOff,
	getProxyAdminToken,
	setProxyAdminToken,
	removeProxyAdminToken,
	getProxyAdminIdToken,
	setProxyAdminIdToken,
	removeProxyAdminIdToken,
} from '@/utils';
import moment, { Moment } from 'moment-timezone';
import { buildNewVuefireBindingArray, buildNewVuefireBindingObj } from '@/mixins';
import axios, { AxiosResponse } from 'axios';
import { NodeRunResultSelectionMode, SELECTED_IMAGE_MODE } from '@/views/Runnits/constants';
import { IMAGE_GALLERY_DIALOG_NAV, ImageGalleryDialogNav } from '@/components/ImageGallery/utils';

Vue.use(Vuex);

/*
Interfaces
 */
export interface PreloadModelState {
	civitai: {
		model: CivitaiModel,
		modelVersion: CivitaiModelVersion,
	},
}
export interface ModelSelectItemsState {
	civitai: CivitaiModel[]
}
export interface LoaderState {
	show: boolean
	message?: string
}
export interface LoginQueryParamsDataState {
	teamId?: string
	inviteCode?: string
	autoLoginSsoProvider?: string
}
export interface AppQueryParamsDataState {
	creatorsClubSignUp?: boolean
	openManageMembership?: boolean
	openManageTeamSubscription?: boolean
}
export interface TeamReportEmbedUrlsDataState {
	questions: Record<string, string>
	dashboards: Record<string, string>
}
export interface UpsellDialogDataState {
	creatorsClub?: boolean
}
interface ToolbarSbudServicesState {
	sessionId: string
	downloadCivitaiModels: boolean
	restart: boolean
	getLogs: boolean
	secrets: Record<string, any>
}
interface ResourcesDialogState {
	open: boolean
	appOffer: AppOffer
}
export interface ToolbarState {
	session: Session
	sbudServicesLoading: boolean
	sbudServices: ToolbarSbudServicesState
	fileBrowserOpen: boolean
	sessionBarOpen: boolean
	sessionBarCollapsed: boolean
	serverBuddyOpen: boolean
	accountMenuOpen: boolean
	insideRunnits: boolean
	mobileMenuOpen: boolean
	notificationsOpen: boolean
	settingsOpen: boolean
	logoutDialogOpen: boolean
	needReauthenticateDialogOpen: boolean
	runnitsSideNavOpen: boolean
	resourcesDialog: ResourcesDialogState
	showFailedInitMessage: boolean
}
export type RunnitsOwnerSelection = 'USER' | 'TEAM';

export interface SelectionState {
	triggeringId: string,
	sortOrder: number, // 0 is falsey, if the sort order is 0 then it is not currently active
	nodeRunResultSelectionMode: NodeRunResultSelectionMode
	fieldAwaitingImageGallerySelection: RunnitNodeField
	selectedNodeRunResults: RunnitNodeRunResult[]
	selectedLoadedAssets: LoadedAsset[]
	selectedUploads: RunnitUpload[]
	selectedAvatars: Avatar[]
}
export const emptySelectionState: SelectionState = {
	triggeringId: null,
	sortOrder: 0,
	nodeRunResultSelectionMode: null,
	fieldAwaitingImageGallerySelection: null,
	selectedNodeRunResults: [],
	selectedLoadedAssets: [],
	selectedUploads: [],
	selectedAvatars: [],
}
export interface RunnitState {
	runnitsOwnerSelection: RunnitsOwnerSelection
	runnitsAccessLevel: RunnitsAccessLevel
	runnit: Runnit
	selectedNodeId: string
	settingsDrawerOpen: boolean
	imageGalleryDialogOpen: boolean
	imageGalleryDialogInitialNav: ImageGalleryDialogNav
	imageGalleryDrawerOpen: boolean
	toolsLibraryDialogOpen: boolean
	generatedNegativePrompt: null

	// creating a new runnit using the settings dialog first
	runnitDraft: Runnit
	runnitDraftNodeRef: RunnitNodeDef

	// Runnit Image Selection mode
	selectionStateHistory: Record<string, SelectionState>
	deletedImages: {
		nodeRunResults: RunnitNodeRunResult[],
		uploads: RunnitUpload[],
		avatars: Avatar[],
	}

	// settings
	selectedImageMode: string
	selectedColumns: number

	// RunnitNodeRun draft helper properties
	isQueuingDraftRunnitNodeRun: boolean

	promptGeneratorOpen: boolean
	imageSelectOpen: boolean
	// Inpainting Editor properties
	inpaintingEditorOpen: boolean
	inpaintingEditorSaving: boolean
	inpaintingBackgroundImageUrl: string
	inpaintingMaskUrl: string
}
export interface SnackbarState {
	status: 'ERROR' | 'SUCCESS' | 'WARN' | 'INFO'
	timeout?: number
	show: boolean
	message: string
	snackbarStyle?: string
}
export interface FingerprintResult {
	visitorId: string
	confidence: {
		score: number
		comment?: string
	}
	components: {
		[key: string]:
			{ value: any, duration: number } |
			{ error: object, duration: number }
	}
	version: string
}
interface Triggers {
	reloadSessionUiIframeTrigger: number
	resetExtensionOffersTrigger: number
	filterExtensionOffersTrigger: number
	imageZoomResetTrigger: number
	fireInitialPromptGenerationTrigger: number
	screenResizeTrigger: number
}
export type StoreLoadingState = true | false | null; // null=HasNotTriedYet | true=Loading | false=DoneLoading
interface State {
	// State for vuefire loading & binding props
	loadingAppOffers: StoreLoadingState, boundPropsAppOffers: { user: User },
	loadingHardwareOffers: StoreLoadingState, boundPropsHardwareOffers: { user: User },
	loadingSoftwareOffers: StoreLoadingState, boundPropsSoftwareOffers: { user: User },
	loadingExtensionOffers: StoreLoadingState, boundPropsExtensionOffers: { user: User },
	loadingStableDiffusionOffers: StoreLoadingState, boundPropsStableDiffusionOffers: { user: User },
	loadingWorkspaces: StoreLoadingState, boundPropsWorkspaces: { teamId: string },
	loadingRunnits: StoreLoadingState, boundPropsRunnits: { userId: string, teamId: string, accessLevel: RunnitsAccessLevel },
	loadingRunnitNodes: StoreLoadingState, boundPropsRunnitNodes: { runnitId: string },
	loadingPublicRunnitNodeDefs: StoreLoadingState, boundPropsPublicRunnitNodeDefs: {},
	loadingTeamRunnitNodeDefs: StoreLoadingState, boundPropsTeamRunnitNodeDefs: {},
	loadingDraftRunnitNodeRun: StoreLoadingState, boundPropsDraftRunnitNodeRun: { userId: string, nodeId: string },
	loadingWorkshops: StoreLoadingState, boundPropsWorkshops: { teamId: string },
	loadingWorkshopSessions: StoreLoadingState, boundPropsWorkshopSessions: { workshopId: string },
	loadingWorkshopSessionQueueItems: StoreLoadingState, boundPropsWorkshopSessionQueueItems: { workshopId: string, workshopSessionId: string },
	loadingLobbyWorkshop: StoreLoadingState, boundPropsLobbyWorkshop: { workshopId: string },
	loadingLobbyWorkshopSession: StoreLoadingState, boundPropsLobbyWorkshopSession: { workshopId: string, workshopSessionId: string },
	loadingLobbyWorkshopSessionQueueItem: StoreLoadingState, boundPropsLobbyWorkshopSessionQueueItem: { workshopId: string, workshopSessionId: string, user: User },
	loadingAnnouncements: StoreLoadingState, boundPropsAnnouncements: {},
	loadingUser: StoreLoadingState, boundPropsUser: { authUser: firebase.User },
	loadingUserBalanceAccount: StoreLoadingState, boundPropsUserBalanceAccount: { user: User },
	loadingTeam: StoreLoadingState, boundPropsTeam: { teamId: string },
	loadingTeamBalanceAccount: StoreLoadingState, boundPropsTeamBalanceAccount: { teamId: string },
	loadingStripeCustomer: StoreLoadingState, boundPropsStripeCustomer: { user: User },
	loadingStripeTeamsCustomer: StoreLoadingState, boundPropsStripeTeamsCustomer: { teamId: string },
	loadingCurrSessions: StoreLoadingState, boundPropsCurrSessions: { user: User },
	loadingCurrSessionBackgroundTasks: StoreLoadingState, boundPropsCurrSessionBackgroundTasks: { session: Session },
	loadingAdminRegularSessions: StoreLoadingState, boundPropsAdminRegularSessions: {},
	loadingStableDiffusionModels: StoreLoadingState, boundPropsStableDiffusionModels: {},
	loadingSetupSessionPrefill: StoreLoadingState, boundPropsSetupSessionPrefill: { authUser: firebase.User, appKey: AppKey },

	// Other state
	loginQueryParamsData: LoginQueryParamsDataState
	appQueryParamsData: AppQueryParamsDataState
	teamReportEmbedUrlsData: TeamReportEmbedUrlsDataState
	teamReportEmbedUrlsDataLoading: boolean
	hasCheckedAuth: boolean
	upsellDialog: UpsellDialogDataState
	authUser: firebase.User
	authUserCredential: firebase.auth.UserCredential
	stripeCustomer: StripeCustomer
	stripeTeamsCustomer: StripeCustomer
	user: User
	userBalanceAccount: BalanceAccount
	team: Team
	teamBalanceAccount: BalanceAccount
	announcements: Announcement[]
	currSessions: Session[]
	currSessionBackgroundTasks: SessionBackgroundTask[]
	pastSessions: Session[]
	pastSessionsLoading: boolean
	pastSessionsCursor: QueryDocumentSnapshot
	workspaces: Workspace[]
	workspacesMap: Record<string, Workspace>
	runnits: Runnit[]
	runnitsMap: Record<string, Runnit>
	runnitNodes: RunnitNode[]
	runnitNodesMap: Record<string, RunnitNode>
	publicRunnitNodeDefs: RunnitNodeDef[]
	publicRunnitNodeDefsMap: Record<string, RunnitNodeDef>
	teamRunnitNodeDefs: RunnitNodeDef[]
	teamRunnitNodeDefsMap: Record<string, RunnitNodeDef>
	draftRunnitNodeRun: RunnitNodeRun
	runnitState: RunnitState
	workshops: Workshop[]
	workshopsMap: Record<string, Workshop>
	workshopSessions: WorkshopSession[]
	workshopSessionsMap: Record<string, WorkshopSession>
	workshopSessionQueueItems: WorkshopSessionQueueItem[]
	workshopSessionQueueItemsMap: Record<string, WorkshopSessionQueueItem>
	lobbyWorkshop: Workshop
	lobbyWorkshopSession: WorkshopSession
	lobbyWorkshopSessionQueueItem: WorkshopSessionQueueItem
	appOffers: AppOffer[]
	appOffersMap: Record<string, AppOffer>
	hardwareOffers: HardwareOffer[]
	hardwareOffersMap: Record<string, HardwareOffer>
	softwareOffers: SoftwareOffer[]
	softwareOffersMap: Record<string, SoftwareOffer>
	extensionOffers: ExtensionOffer[]
	extensionOffersMap: Record<string, ExtensionOffer>
	extensionOffersKeyMap: Record<string, ExtensionOffer>
	adminRegularSessions: Session[]
	stableDiffusionOffers: StableDiffusionOffer[]
	stableDiffusionModels: StableDiffusionModel[]
	stableDiffusionModel: StableDiffusionModel
	setupSessionPrefill: SetupSessionPrefill
	preloadModel: PreloadModelState
	modelSelectItems: ModelSelectItemsState
	loader: LoaderState
	snackbar: SnackbarState
	toolbar: ToolbarState
	fingerprintResult: FingerprintResult
	realTimeMoment: Moment
	sessionSoundsOff: boolean
	isAdminProxyingUser: boolean
	triggers: Triggers
}

/*
Store
 */
export default new Vuex.Store({
	state: {
		loadingAppOffers: null, boundPropsAppOffers: { user: null },
		loadingHardwareOffers: null, boundPropsHardwareOffers: { user: null },
		loadingSoftwareOffers: null, boundPropsSoftwareOffers: { user: null },
		loadingExtensionOffers: null, boundPropsExtensionOffers: { user: null },
		loadingStableDiffusionOffers: null, boundPropsStableDiffusionOffers: { user: null },
		loadingWorkspaces: null, boundPropsWorkspaces: { teamId: null },
		loadingRunnits: null, boundPropsRunnits: { userId: null, teamId: null, accessLevel: null },
		loadingRunnitNodes: null, boundPropsRunnitNodes: { runnitId: null },
		loadingPublicRunnitNodeDefs: null, boundPropsPublicRunnitNodeDefs: {},
		loadingTeamRunnitNodeDefs: null, boundPropsTeamRunnitNodeDefs: {},
		loadingDraftRunnitNodeRun: null, boundPropsDraftRunnitNodeRun: { userId: null, nodeId: null },
		loadingWorkshops: null, boundPropsWorkshops: { teamId: null },
		loadingWorkshopSessions: null, boundPropsWorkshopSessions: { workshopId: null },
		loadingWorkshopSessionQueueItems: null, boundPropsWorkshopSessionQueueItems: { workshopId: null, workshopSessionId: null },
		loadingLobbyWorkshop: null, boundPropsLobbyWorkshop: { workshopId: null },
		loadingLobbyWorkshopSession: null, boundPropsLobbyWorkshopSession: { workshopId: null, workshopSessionId: null },
		loadingLobbyWorkshopSessionQueueItem: null, boundPropsLobbyWorkshopSessionQueueItem: { workshopId: null, workshopSessionId: null, user: null },
		loadingAnnouncements: null, boundPropsAnnouncements: {},
		loadingUser: null, boundPropsUser: { authUser: null },
		loadingUserBalanceAccount: null, boundPropsUserBalanceAccount: { user: null },
		loadingTeam: null, boundPropsTeam: { teamId: null },
		loadingTeamBalanceAccount: null, boundPropsTeamBalanceAccount: { teamId: null },
		loadingStripeCustomer: null, boundPropsStripeCustomer: { user: null },
		loadingStripeTeamsCustomer: null, boundPropsStripeTeamsCustomer: { teamId: null },
		loadingCurrSessions: null, boundPropsCurrSessions: { user: null },
		loadingCurrSessionBackgroundTasks: null, boundPropsCurrSessionBackgroundTasks: { session: null },
		loadingAdminRegularSessions: null, boundPropsAdminRegularSessions: { user: null },
		loadingStableDiffusionModels: null, boundPropsStableDiffusionModels: {},
		loadingSetupSessionPrefill: null, boundPropsSetupSessionPrefill: { authUser: null, appKey: null },

		loginQueryParamsData: {},
		appQueryParamsData: {},
		teamReportEmbedUrlsData: null,
		teamReportEmbedUrlsDataLoading: false,
		hasCheckedAuth: false,
		upsellDialog: {},
		authUser: null,
		authUserCredential: null,
		stripeCustomer: null,
		stripeTeamsCustomer: null,
		user: null,
		userBalanceAccount: null,
		team: null,
		teamBalanceAccount: null,
		workspaces: [],
		workspacesMap: {},
		runnits: [],
		runnitsMap: {},
		runnitNodes: [],
		runnitNodesMap: {},
		publicRunnitNodeDefs: [],
		publicRunnitNodeDefsMap: {},
		teamRunnitNodeDefs: [],
		teamRunnitNodeDefsMap: {},
		draftRunnitNodeRun: null,
		runnitState: {
			runnitsOwnerSelection: null,
			runnitsAccessLevel: null,
			runnitDraft: null,
			runnitDraftNodeRef: null,
			runnit: null,
			selectedNodeId: null,
			settingsDrawerOpen: false,
			imageGalleryDialogOpen: false,
			imageGalleryDialogInitialNav: IMAGE_GALLERY_DIALOG_NAV.GENERATIONS,
			imageGalleryDrawerOpen: false,
			toolsLibraryDialogOpen: false,
			generatedNegativePrompt: null, // Set by the PromptGenerator component, to fill in the negative prompt fields in the form
			selectionStateHistory: {},
			deletedImages: {
				nodeRunResults: [],
				uploads: [],
				avatars: [],
			},
			selectedImageMode: SELECTED_IMAGE_MODE.TILED,
			selectedColumns: 3,
			isQueuingDraftRunnitNodeRun: false,
			promptGeneratorOpen: false,
			imageSelectOpen: false,
			inpaintingEditorOpen: false,
			inpaintingEditorSaving: false,
			inpaintingBackgroundImageUrl: null,
			inpaintingMaskUrl: null,
		},
		workshops: [],
		workshopsMap: {},
		workshopSessions: [],
		workshopSessionsMap: {},
		workshopSessionQueueItems: [],
		workshopSessionQueueItemsMap: {},
		lobbyWorkshop: null,
		lobbyWorkshopSession: null,
		lobbyWorkshopSessionQueueItem: null,
		announcements: [],
		setupSessionPrefill: null,

		currSessions: [],
		currSessionBackgroundTasks: [],
		pastSessions: [],
		pastSessionsLoading: false,
		pastSessionsCursor: null,

		appOffers: [],
		appOffersMap: {},
		hardwareOffers: [],
		hardwareOffersMap: {},
		softwareOffers: [],
		softwareOffersMap: {},
		extensionOffers: [],
		extensionOffersMap: {},
		extensionOffersKeyMap: {},
		adminRegularSessions: [],
		stableDiffusionOffers: [],
		stableDiffusionModels: [],
		stableDiffusionModel: null,
		modelSelectItems: {
			civitai: [],
		},
		preloadModel: {
			civitai: {
				model: null,
				modelVersion: null,
			},
		},

		loader: {
			show: false,
			message: '',
		},
		snackbar: {
			status: SNACKBAR_STATUS.INFO,
			timeout: null,
			show: false,
			message: '',
			snackbarStyle: '',
		},
		toolbar: {
			session: null,
			sbudServicesLoading: false,
			sbudServices: {
				sessionId: null,
				downloadCivitaiModels: false,
				restart: false,
				getLogs: false,
				secrets: {},
			},
			fileBrowserOpen: false,
			sessionBarOpen: false,
			sessionBarCollapsed: true,
			serverBuddyOpen: false,
			accountMenuOpen: false,
			insideRunnits: false,
			mobileMenuOpen: false,
			notificationsOpen: false,
			settingsOpen: false,
			logoutDialogOpen: false,
			needReauthenticateDialogOpen: false,
			runnitsSideNavOpen: false,
			resourcesDialog: {
				open: false,
				appOffer: null,
			},
			showFailedInitMessage: false,
		},
		fingerprintResult: null,
		realTimeMoment: moment(),
		sessionSoundsOff: getSessionSoundsOff(),
		isAdminProxyingUser: !!getProxyAdminToken(),
		triggers: {
			reloadSessionUiIframeTrigger: 0,
			resetExtensionOffersTrigger: 0,
			filterExtensionOffersTrigger: 0,
			imageZoomResetTrigger: 0,
			fireInitialPromptGenerationTrigger: 0,
			screenResizeTrigger: 0,
		},
	} as State,

	getters: {
		isLoggedIn: (state: State): boolean => !!(
			state.authUser &&
			state.authUser.uid &&
			state.user &&
			state.authUser.uid === state.user.id
		),
		isLoggingIn: (state: State): boolean => !!(
			!state.hasCheckedAuth ||
			(
				state.authUser &&
				state.authUser.uid &&
				(
					!state.user ||
					state.authUser.uid !== state.user.id
				)
			)
		),
		isNewUserTrialFlow: (state: State): boolean => !!(
			state.userBalanceAccount &&
			state.user &&
			!state.userBalanceAccount.lastSessionAt &&
			_isEmpty(state.user.teamIds)
		),
		showStripeUserMembershipPortalBtn: (state: State): boolean => !!(
			state.stripeCustomer &&
			state.stripeCustomer.stripeId &&
			state.user &&
			state.user.clubOffer &&
			typeof state.user.clubOffer !== 'string' &&
			!state.user.clubOffer.isFreemium
		),
		showStripeTeamSubscriptionPortalBtn: (state: State): boolean => !!(
			state.stripeTeamsCustomer &&
			state.stripeTeamsCustomer.stripeId &&
			state.team
		),
		isMobileNavOpen: (state: State): boolean => {
			state.triggers.screenResizeTrigger;
			return window.innerHeight < document.querySelector('#measure-vh').clientHeight;
		},
		clubOfferBenefits: (state: State): ClubOfferBenefits => {
			return (
				state.user &&
				state.user.clubOffer &&
				typeof state.user.clubOffer !== 'string' &&
				state.user.clubOffer.benefits
			) || {
				isCc: false,
				dailyRunnitTokens: false,
				monthlyRunnitTokens: false,
			};
		},
	},

	mutations: {
		/*
		VuexFirestoreBindingsMixin - Loading & BoundProps
		 */
		setBoundPropsAppOffers: ((state: State, payload: { user: User }) => {
			state.boundPropsAppOffers = payload;
		}),
		setBoundPropsHardwareOffers: ((state: State, payload: { user: User }) => {
			state.boundPropsHardwareOffers = payload;
		}),
		setBoundPropsSoftwareOffers: ((state: State, payload: { user: User }) => {
			state.boundPropsSoftwareOffers = payload;
		}),
		setBoundPropsExtensionOffers: ((state: State, payload: { user: User }) => {
			state.boundPropsExtensionOffers = payload;
		}),
		setBoundPropsStableDiffusionOffers: ((state: State, payload: { user: User }) => {
			state.boundPropsStableDiffusionOffers = payload;
		}),
		setBoundPropsWorkspaces: ((state: State, payload: { teamId: string }) => {
			state.boundPropsWorkspaces = payload;
		}),
		setBoundPropsRunnits: ((state: State, payload: { userId: string, teamId: string, accessLevel: RunnitsAccessLevel }) => {
			state.boundPropsRunnits = payload;
		}),
		setBoundPropsRunnitNodes: ((state: State, payload: { runnitId: string }) => {
			state.boundPropsRunnitNodes = payload;
		}),
		setBoundPropsPublicRunnitNodeDefs: ((state: State, payload: {}) => {
			state.boundPropsPublicRunnitNodeDefs = payload;
		}),
		setBoundPropsTeamRunnitNodeDefs: ((state: State, payload: { teamId: string }) => {
			state.boundPropsTeamRunnitNodeDefs = payload;
		}),
		setBoundPropsDraftRunnitNodeRun: ((state: State, payload: { userId: string, nodeId: string }) => {
			state.boundPropsDraftRunnitNodeRun = payload;
		}),
		setBoundPropsWorkshops: ((state: State, payload: { teamId: string }) => {
			state.boundPropsWorkshops = payload;
		}),
		setBoundPropsWorkshopSessions: ((state: State, payload: { workshopId: string }) => {
			state.boundPropsWorkshopSessions = payload;
		}),
		setBoundPropsWorkshopSessionQueueItems: ((state: State, payload: { workshopId: string, workshopSessionId: string }) => {
			state.boundPropsWorkshopSessionQueueItems = payload;
		}),
		setBoundPropsLobbyWorkshop: ((state: State, payload: { workshopId: string }) => {
			state.boundPropsLobbyWorkshop = payload;
		}),
		setBoundPropsLobbyWorkshopSession: ((state: State, payload: { workshopId: string, workshopSessionId: string }) => {
			state.boundPropsLobbyWorkshopSession = payload;
		}),
		setBoundPropsLobbyWorkshopSessionQueueItem: ((state: State, payload: { workshopId: string, workshopSessionId: string, user: User }) => {
			state.boundPropsLobbyWorkshopSessionQueueItem = payload;
		}),
		setBoundPropsAnnouncements: ((state: State, payload: {}) => {
			state.boundPropsAnnouncements = payload;
		}),
		setBoundPropsUser: ((state: State, payload: { authUser: firebase.User }) => {
			state.boundPropsUser = payload;
		}),
		setBoundPropsUserBalanceAccount: ((state: State, payload: { user: User }) => {
			state.boundPropsUserBalanceAccount = payload;
		}),
		setBoundPropsTeam: ((state: State, payload: { teamId: string }) => {
			state.boundPropsTeam = payload;
		}),
		setBoundPropsTeamBalanceAccount: ((state: State, payload: { teamId: string }) => {
			state.boundPropsTeamBalanceAccount = payload;
		}),
		setBoundPropsStripeCustomer: ((state: State, payload: { user: User }) => {
			state.boundPropsStripeCustomer = payload;
		}),
		setBoundPropsStripeTeamsCustomer: ((state: State, payload: { teamId: string }) => {
			state.boundPropsStripeTeamsCustomer = payload;
		}),
		setBoundPropsCurrSessions: ((state: State, payload: { user: User }) => {
			state.boundPropsCurrSessions = payload;
		}),
		setBoundPropsCurrSessionBackgroundTasks: ((state: State, payload: { session: Session }) => {
			state.boundPropsCurrSessionBackgroundTasks = payload;
		}),
		setBoundPropsAdminRegularSessions: ((state: State, payload: { user: User }) => {
			state.boundPropsAdminRegularSessions = payload;
		}),
		setBoundPropsStableDiffusionModels: ((state: State, payload: {}) => {
			state.boundPropsStableDiffusionModels = payload;
		}),
		setBoundPropsSetupSessionPrefill: ((state: State, payload: { authUser: firebase.User, appKey: AppKey }) => {
			state.boundPropsSetupSessionPrefill = payload;
		}),

		/*
		VuexFirestoreBindingsMixin - Actual binding results
		Mutate the object, because vuefire reuses the collection
		 */
		resultAppOffers: ((state: State, payload: AppOffer[]) => {
			const newArrayPayload: AppOffer[] = buildNewVuefireBindingArray(
				_sortBy(payload || [], 'sortOrder').map((appOffer: AppOffer) => {
					return buildNewVuefireBindingObj(appOffer, {
						tags: (appOffer.tags || []).sort(),
					});
				}),
			).filter((appOffer: AppOffer) => (
				(state.user && state.user.isAdmin) ||
				(
					(
						(!appOffer.clubs || !appOffer.clubs.length) &&
						!appOffer.isTeam
					) ||
					(
						appOffer.isTeam &&
						state.team &&
						state.team.isActive
					) ||
					(
						appOffer.clubs &&
						appOffer.clubs.length &&
						state.user &&
						state.user.club &&
						appOffer.clubs.includes(state.user.club)
					)
				)
			));
			state.appOffers = newArrayPayload;
			state.appOffersMap = _mapKeys(newArrayPayload, 'id');
		}),
		resultHardwareOffers: ((state: State, payload: HardwareOffer[]) => {
			const newArrayPayload: HardwareOffer[] = buildNewVuefireBindingArray(
				_sortBy(payload || [], 'sortOrder'),
			).filter((hardwareOffer: HardwareOffer) => (
				(state.user && state.user.isAdmin) ||
				(
					(
						(!hardwareOffer.clubs || !hardwareOffer.clubs.length) &&
						!hardwareOffer.isTeam
					) ||
					(
						hardwareOffer.isTeam &&
						state.team &&
						state.team.isActive
					) ||
					(
						hardwareOffer.clubs &&
						hardwareOffer.clubs.length &&
						state.user &&
						state.user.club &&
						hardwareOffer.clubs.includes(state.user.club)
					)
				)
			));
			state.hardwareOffers = newArrayPayload;
			state.hardwareOffersMap = _mapKeys(newArrayPayload, 'id');
		}),
		resultSoftwareOffers: ((state: State, payload: SoftwareOffer[]) => {
			const newArrayPayload: SoftwareOffer[] = buildNewVuefireBindingArray(
				_sortBy(payload || [], 'sortOrder'),
			).filter((softwareOffer: SoftwareOffer) => (
				(state.user && state.user.isAdmin) ||
				(
					(
						(!softwareOffer.clubs || !softwareOffer.clubs.length) &&
						!softwareOffer.isTeam
					) ||
					(
						softwareOffer.isTeam &&
						state.team &&
						state.team.isActive
					) ||
					(
						softwareOffer.clubs &&
						softwareOffer.clubs.length &&
						state.user &&
						state.user.club &&
						softwareOffer.clubs.includes(state.user.club)
					)
				)
			));
			state.softwareOffers = newArrayPayload;
			state.softwareOffersMap = _mapKeys(newArrayPayload, 'id');
		}),
		resultExtensionOffers: ((state: State, payload: ExtensionOffer[]) => {
			const newArrayPayload: ExtensionOffer[] = buildNewVuefireBindingArray(
				_sortBy(payload || [], 'sortOrder'),
			);
			state.extensionOffers = newArrayPayload;
			state.extensionOffersMap = _mapKeys(newArrayPayload, 'id');
			state.extensionOffersKeyMap = _mapKeys(newArrayPayload, 'key');
		}),
		resultStableDiffusionOffers: ((state: State, payload: StableDiffusionOffer[]) => {
			state.stableDiffusionOffers = buildNewVuefireBindingArray(
				_sortBy(payload || [], 'sortOrder'),
			);
		}),
		resultWorkspaces: ((state: State, payload: Workspace[]) => {
			const newArrayPayload: Workspace[] = buildNewVuefireBindingArray(payload);
			state.workspaces = newArrayPayload;
			state.workspacesMap = _mapKeys(newArrayPayload, 'id');
		}),
		resultRunnits: ((state: State, payload: Runnit[]) => {
			const newArrayPayload: Runnit[] = buildNewVuefireBindingArray(payload);
			state.runnits = newArrayPayload;
			state.runnitsMap = _mapKeys(newArrayPayload, 'id');
		}),
		resultRunnitNodes: ((state: State, payload: RunnitNode[]) => {
			const newArrayPayload: RunnitNode[] = buildNewVuefireBindingArray(payload);
			state.runnitNodes = newArrayPayload;
			state.runnitNodesMap = _mapKeys(newArrayPayload, 'id');
		}),
		resultPublicRunnitNodeDefs: ((state: State, payload: RunnitNodeDef[]) => {
			const newArrayPayload: RunnitNodeDef[] = buildNewVuefireBindingArray(
				_sortBy(payload || [], 'sortOrder'),
			);
			state.publicRunnitNodeDefs = newArrayPayload;
			state.publicRunnitNodeDefsMap = _mapKeys(newArrayPayload, 'id');
		}),
		resultTeamRunnitNodeDefs: ((state: State, payload: RunnitNodeDef[]) => {
			const newArrayPayload: RunnitNodeDef[] = buildNewVuefireBindingArray(
				_sortBy(payload || [], 'sortOrder'),
			);
			state.teamRunnitNodeDefs = newArrayPayload;
			state.teamRunnitNodeDefsMap = _mapKeys(newArrayPayload, 'id');
		}),
		resultDraftRunnitNodeRun: ((state: State, payload: RunnitNodeRun[]) => {
			const firstRunnitNodeRun: RunnitNodeRun = _get(payload, '[0]') || null;
			state.draftRunnitNodeRun = buildNewVuefireBindingObj(firstRunnitNodeRun);
		}),
		resultWorkshops: ((state: State, payload: Workshop[]) => {
			const newArrayPayload: Workshop[] = buildNewVuefireBindingArray(payload);
			state.workshops = newArrayPayload;
			state.workshopsMap = _mapKeys(newArrayPayload, 'id');
		}),
		resultWorkshopSessions: ((state: State, payload: WorkshopSession[]) => {
			const newArrayPayload: WorkshopSession[] = buildNewVuefireBindingArray(payload);
			state.workshopSessions = newArrayPayload;
			state.workshopSessionsMap = _mapKeys(newArrayPayload, 'id');
		}),
		resultWorkshopSessionQueueItems: ((state: State, payload: WorkshopSessionQueueItem[]) => {
			const newArrayPayload: WorkshopSessionQueueItem[] = buildNewVuefireBindingArray(payload);
			state.workshopSessionQueueItems = newArrayPayload;
			state.workshopSessionQueueItemsMap = _mapKeys(newArrayPayload, 'id');
		}),
		resultLobbyWorkshop: ((state: State, payload: Workshop) => {
			state.lobbyWorkshop = buildNewVuefireBindingObj(payload);
		}),
		resultLobbyWorkshopSession: ((state: State, payload: WorkshopSession) => {
			state.lobbyWorkshopSession = buildNewVuefireBindingObj(payload);
		}),
		resultLobbyWorkshopSessionQueueItem: ((state: State, payload: WorkshopSessionQueueItem) => {
			state.lobbyWorkshopSessionQueueItem = buildNewVuefireBindingObj(payload);
		}),
		resultAnnouncements: ((state: State, payload: Announcement[]) => {
			state.announcements = buildNewVuefireBindingArray(payload);
		}),
		resultUser: ((state: State, payload: User) => {
			state.user = buildNewVuefireBindingObj(payload);
		}),
		resultUserBalanceAccount: ((state: State, payload: BalanceAccount) => {
			state.userBalanceAccount = buildNewVuefireBindingObj(payload);
		}),
		resultTeam: ((state: State, payload: Team) => {
			state.team = buildNewVuefireBindingObj(payload);
		}),
		resultTeamBalanceAccount: ((state: State, payload: BalanceAccount) => {
			state.teamBalanceAccount = buildNewVuefireBindingObj(payload);
		}),
		resultStripeCustomer: ((state: State, payload: StripeCustomer) => {
			state.stripeCustomer = buildNewVuefireBindingObj(payload);
		}),
		resultStripeTeamsCustomer: ((state: State, payload: StripeCustomer) => {
			state.stripeTeamsCustomer = buildNewVuefireBindingObj(payload);
		}),
		resultCurrSessions: ((state: State, payload: Session[]) => {
			state.currSessions = buildNewVuefireBindingArray(payload);
		}),
		resultCurrSessionBackgroundTasks: ((state: State, payload: Session[]) => {
			state.currSessionBackgroundTasks = buildNewVuefireBindingArray(payload);
		}),
		resultAdminRegularSessions: ((state: State, payload: Session[]) => {
			state.adminRegularSessions = buildNewVuefireBindingArray(payload);
		}),
		resultStableDiffusionModels: ((state: State, payload: StableDiffusionModel[]) => {
			state.stableDiffusionModels = buildNewVuefireBindingArray(payload);
		}),
		resultSetupSessionPrefill: ((state: State, payload: SetupSessionPrefill) => {
			state.setupSessionPrefill = buildNewVuefireBindingObj(payload);
		}),

		/*
		VuexFirestoreBindingsMixin - Set loading booleans for initial fetch
		 */
		setLoadingAppOffers: ((state: State, loading: boolean) => {
			state.loadingAppOffers = !!loading;
		}),
		setLoadingHardwareOffers: ((state: State, loading: boolean) => {
			state.loadingHardwareOffers = !!loading;
		}),
		setLoadingSoftwareOffers: ((state: State, loading: boolean) => {
			state.loadingSoftwareOffers = !!loading;
		}),
		setLoadingExtensionOffers: ((state: State, loading: boolean) => {
			state.loadingExtensionOffers = !!loading;
		}),
		setLoadingStableDiffusionOffers: ((state: State, loading: boolean) => {
			state.loadingStableDiffusionOffers = !!loading;
		}),
		setLoadingWorkspaces: ((state: State, loading: boolean) => {
			state.loadingWorkspaces = !!loading;
		}),
		setLoadingRunnits: ((state: State, loading: boolean) => {
			state.loadingRunnits = !!loading;
		}),
		setLoadingRunnitNodes: ((state: State, loading: boolean) => {
			state.loadingRunnitNodes = !!loading;
		}),
		setLoadingPublicRunnitNodeDefs: ((state: State, loading: boolean) => {
			state.loadingPublicRunnitNodeDefs = !!loading;
		}),
		setLoadingTeamRunnitNodeDefs: ((state: State, loading: boolean) => {
			state.loadingTeamRunnitNodeDefs = !!loading;
		}),
		setLoadingDraftRunnitNodeRun: ((state: State, loading: boolean) => {
			state.loadingDraftRunnitNodeRun = !!loading;
		}),
		setLoadingWorkshops: ((state: State, loading: boolean) => {
			state.loadingWorkshops = !!loading;
		}),
		setLoadingWorkshopSessions: ((state: State, loading: boolean) => {
			state.loadingWorkshopSessions = !!loading;
		}),
		setLoadingWorkshopSessionQueueItems: ((state: State, loading: boolean) => {
			state.loadingWorkshopSessionQueueItems = !!loading;
		}),
		setLoadingLobbyWorkshop: ((state: State, loading: boolean) => {
			state.loadingLobbyWorkshop = !!loading;
		}),
		setLoadingLobbyWorkshopSession: ((state: State, loading: boolean) => {
			state.loadingLobbyWorkshopSession = !!loading;
		}),
		setLoadingLobbyWorkshopSessionQueueItem: ((state: State, loading: boolean) => {
			state.loadingLobbyWorkshopSessionQueueItem = !!loading;
		}),
		setLoadingAnnouncements: ((state: State, loading: boolean) => {
			state.loadingAnnouncements = !!loading;
		}),
		setLoadingUser: ((state: State, loading: boolean) => {
			state.loadingUser = !!loading;
		}),
		setLoadingUserBalanceAccount: ((state: State, loading: boolean) => {
			state.loadingUserBalanceAccount = !!loading;
		}),
		setLoadingTeam: ((state: State, loading: boolean) => {
			state.loadingTeam = !!loading;
		}),
		setLoadingTeamBalanceAccount: ((state: State, loading: boolean) => {
			state.loadingTeamBalanceAccount = !!loading;
		}),
		setLoadingStripeCustomer: ((state: State, loading: boolean) => {
			state.loadingStripeCustomer = !!loading;
		}),
		setLoadingStripeTeamsCustomer: ((state: State, loading: boolean) => {
			state.loadingStripeTeamsCustomer = !!loading;
		}),
		setLoadingCurrSessions: ((state: State, loading: boolean) => {
			state.loadingCurrSessions = !!loading;
		}),
		setLoadingCurrSessionBackgroundTasks: ((state: State, loading: boolean) => {
			state.loadingCurrSessionBackgroundTasks = !!loading;
		}),
		setLoadingAdminRegularSessions: ((state: State, loading: boolean) => {
			state.loadingAdminRegularSessions = !!loading;
		}),
		setLoadingStableDiffusionModels: ((state: State, loading: boolean) => {
			state.loadingStableDiffusionModels = !!loading;
		}),
		setLoadingSetupSessionPrefill: ((state: State, loading: boolean) => {
			state.loadingSetupSessionPrefill = !!loading;
		}),

		/*
		Other
		 */
		setAuthUser (state: State, authUser: firebase.User) {
			state.hasCheckedAuth = true;
			state.authUser = authUser || null;
			if (
				!authUser ||
				(state.authUserCredential && authUser.uid !== state.authUserCredential.user.uid)
			) {
				state.authUserCredential = null;
			}
		},
		setAuthUserCredential (state: State, authUserCredential: firebase.auth.UserCredential) {
			state.authUserCredential = authUserCredential || null;
			state.authUser = (authUserCredential && authUserCredential.user) || null;
		},
		setAdminUserProxyTokens (state: State, { proxyAdminToken, proxyAdminIdToken }) {
			state.isAdminProxyingUser = !!proxyAdminToken;
			if (proxyAdminToken) {
				setProxyAdminToken(proxyAdminToken);
				setProxyAdminIdToken(proxyAdminIdToken);
			} else {
				removeProxyAdminToken();
				removeProxyAdminIdToken();
			}
		},
		updateLoginQueryParamsData (state: State, loginQueryParamsData: LoginQueryParamsDataState) {
			state.loginQueryParamsData = {
				...state.loginQueryParamsData,
				...loginQueryParamsData,
			};
		},
		updateAppQueryParamsData (state: State, appQueryParamsData: AppQueryParamsDataState) {
			state.appQueryParamsData = {
				...state.appQueryParamsData,
				...appQueryParamsData,
			};
		},
		setTeamReportEmbedUrlsData (state: State, teamReportEmbedUrlsData: TeamReportEmbedUrlsDataState) {
			state.teamReportEmbedUrlsData = {
				...state.teamReportEmbedUrlsData,
				...teamReportEmbedUrlsData,
			};
		},
		setTeamReportEmbedUrlsDataLoading (state: State, loading: boolean) {
			state.teamReportEmbedUrlsDataLoading = !!loading;
		},
		updateUpsellDialog (state: State, upsellDialog: UpsellDialogDataState) {
			state.upsellDialog = {
				...state.upsellDialog,
				...upsellDialog,
			};
		},
		updateRunnitState (state: State, runnitState: RunnitState) {
			state.runnitState = {
				...state.runnitState,
				...runnitState,
			};
		},
		updateToolbar (state: State, toolbar: ToolbarState) {
			const showFailedInitMessage: boolean = !!(
				_has(toolbar, 'showFailedInitMessage')
					? toolbar.showFailedInitMessage
					: (
						state.toolbar.showFailedInitMessage ||
						(
							_get(toolbar, 'session.stopCode') === STOP_CODE.FAILED_INIT &&
							_get(toolbar, 'session.state') === SESSION_STATE.STOP
						)
					)
			);

			const closeAllDrawers: boolean = !!(
				(toolbar.accountMenuOpen && !state.toolbar.accountMenuOpen) ||
				(toolbar.mobileMenuOpen && !state.toolbar.mobileMenuOpen) ||
				(toolbar.settingsOpen && !state.toolbar.settingsOpen) ||
				(toolbar.notificationsOpen && !state.toolbar.notificationsOpen) ||
				(toolbar.serverBuddyOpen && !state.toolbar.serverBuddyOpen) ||
				(_get(toolbar, 'resourcesDialog.open') && !_get(state.toolbar, 'resourcesDialog.open'))
			);

			state.toolbar = {
				...state.toolbar,
				...(closeAllDrawers && {
					accountMenuOpen: false,
					mobileMenuOpen: false,
					settingsOpen: false,
					notificationsOpen: false,
					serverBuddyOpen: false,
					resourcesDialog: {
						open: false,
						appOffer: null,
					},
				}),
				...toolbar,
				showFailedInitMessage,
			};
		},
		updateLoader (state: State, loader: LoaderState) {
			state.loader = {
				...state.loader,
				...loader,
				...(loader && !loader.show && {
					message: null,
				}),
			};
		},
		updateSnackbar (state: State, snackbar: SnackbarState) {
			state.snackbar = {
				...state.snackbar,
				...snackbar,
			};
		},
		updatePreloadModel (state: State, { providerKey, result }) {
			state.preloadModel = {
				...state.preloadModel,
				[providerKey]: result || null,
			};
		},
		updateModelSelectItems (state: State, { providerKey, results }) {
			state.modelSelectItems = {
				...state.modelSelectItems,
				[providerKey]: results || [],
			};
		},
		setPastSessions (state: State, sessions: Session[]) {
			state.pastSessions = _uniqBy(sessions || [], 'id');
		},
		setPastSessionsLoading (state: State, loading: boolean) {
			state.pastSessionsLoading = !!loading;
		},
		setPastSessionsCursor (state: State, cursor: QueryDocumentSnapshot) {
			state.pastSessionsCursor = cursor || null;
		},
		setStableDiffusionModel (state: State, stableDiffusionModel: StableDiffusionModel) {
			state.stableDiffusionModel = stableDiffusionModel;
		},
		setFingerprintResult (state: State, fingerprintResult: FingerprintResult) {
			state.fingerprintResult = fingerprintResult || null;
		},
		tickRealTimeMoment (state: State) {
			state.realTimeMoment = moment();
		},
		setSessionSoundsOff (state: State, sessionSoundsOff: boolean) {
			if (sessionSoundsOff) {
				setSessionSoundsOff();
			} else {
				removeSessionSoundsOff();
			}
			state.sessionSoundsOff = !!sessionSoundsOff;
		},
		setSbudServicesLoading (state: State, loading: boolean) {
			state.toolbar = {
				...state.toolbar,
				sbudServicesLoading: !!loading,
			};
		},
		setSbudServices (state: State, sbudServices: ToolbarSbudServicesState) {
			state.toolbar = {
				...state.toolbar,
				sbudServices,
			};
		},
		incrementReloadSessionUiIframeTrigger (state: State) {
			state.triggers.reloadSessionUiIframeTrigger = state.triggers.reloadSessionUiIframeTrigger + 1;
		},
		incrementResetExtensionOffersTrigger (state: State) {
			state.triggers.resetExtensionOffersTrigger = state.triggers.resetExtensionOffersTrigger + 1;
		},
		incrementFilterExtensionOffersTrigger (state: State) {
			state.triggers.filterExtensionOffersTrigger = state.triggers.filterExtensionOffersTrigger + 1;
		},
		incrementImageZoomResetTrigger (state: State) {
			state.triggers.imageZoomResetTrigger = state.triggers.imageZoomResetTrigger + 1;
		},
		incrementFireInitialPromptGenerationTrigger (state: State) {
			state.triggers.fireInitialPromptGenerationTrigger = state.triggers.fireInitialPromptGenerationTrigger + 1;
		},
		incrementScreenResizeTrigger (state: State) {
			state.triggers.screenResizeTrigger = state.triggers.screenResizeTrigger + 1;
		},
	},

	actions: {
		/*
		VuexFirestoreBindingsMixin - BoundProps
		 */
		bindAppOffers: (({ commit }, user: User) => {
			commit('setBoundPropsAppOffers', { user });
		}),
		bindHardwareOffers: (({ commit }, user: User) => {
			commit('setBoundPropsHardwareOffers', { user });
		}),
		bindSoftwareOffers: (({ commit }, user: User) => {
			commit('setBoundPropsSoftwareOffers', { user });
		}),
		bindExtensionOffers: (({ commit }, user: User) => {
			commit('setBoundPropsExtensionOffers', { user });
		}),
		bindStableDiffusionOffers: (({ commit }, user: User) => {
			commit('setBoundPropsStableDiffusionOffers', { user });
		}),
		bindWorkspaces: (({ commit }, teamId: string) => {
			commit('setBoundPropsWorkspaces', { teamId });
		}),
		unbindWorkspaces: (({ commit }) => {
			commit('setBoundPropsWorkspaces', { teamId: null });
		}),
		bindRunnits: (({ commit }, { userId, teamId, accessLevel }) => {
			commit('setBoundPropsRunnits', { userId, teamId, accessLevel });
		}),
		unbindRunnits: (({ commit }) => {
			commit('setBoundPropsRunnits', { userId: null, teamId: null, accessLevel: null });
		}),
		bindRunnitNodes: (({ commit }, { runnitId }) => {
			commit('setBoundPropsRunnitNodes', { runnitId });
		}),
		unbindRunnitNodes: (({ commit }) => {
			commit('setBoundPropsRunnitNodes', { runnitId: null });
		}),
		bindPublicRunnitNodeDefs: (({ commit }) => {
			commit('setBoundPropsPublicRunnitNodeDefs', {});
		}),
		unbindPublicRunnitNodeDefs: (({ commit }) => {
			commit('setBoundPropsPublicRunnitNodeDefs', {});
		}),
		bindTeamRunnitNodeDefs: (({ commit }, teamId: string) => {
			commit('setBoundPropsTeamRunnitNodeDefs', { teamId });
		}),
		unbindTeamRunnitNodeDefs: (({ commit }) => {
			commit('setBoundPropsTeamRunnitNodeDefs', {});
		}),
		bindDraftRunnitNodeRun: (({ commit }, { userId, nodeId }) => {
			commit('setBoundPropsDraftRunnitNodeRun', { userId, nodeId });
		}),
		unbindDraftRunnitNodeRun: (({ commit }) => {
			commit('setBoundPropsDraftRunnitNodeRun', { userId: null, nodeId: null });
		}),
		bindWorkshops: (({ commit }, teamId: string) => {
			commit('setBoundPropsWorkshops', { teamId });
		}),
		unbindWorkshops: (({ commit }) => {
			commit('setBoundPropsWorkshops', { teamId: null });
		}),
		bindWorkshopSessions: (({ commit }, workshopId: string) => {
			commit('setBoundPropsWorkshopSessions', { workshopId });
		}),
		unbindWorkshopSessions: (({ commit }) => {
			commit('setBoundPropsWorkshopSessions', { workshopId: null });
		}),
		bindWorkshopSessionQueueItems: (({ commit }, { workshopId, workshopSessionId }) => {
			commit('setBoundPropsWorkshopSessionQueueItems', { workshopId, workshopSessionId });
		}),
		unbindWorkshopSessionQueueItems: (({ commit }) => {
			commit('setBoundPropsWorkshopSessionQueueItems', { workshopId: null, workshopSessionId: null });
		}),
		bindLobbyWorkshop: (({ commit }, workshopId: string) => {
			commit('setBoundPropsLobbyWorkshop', { workshopId });
		}),
		unbindLobbyWorkshop: (({ commit }) => {
			commit('setBoundPropsLobbyWorkshop', { workshopId: null });
		}),
		bindLobbyWorkshopSession: (({ commit }, { workshopId, workshopSessionId }) => {
			commit('setBoundPropsLobbyWorkshopSession', { workshopId, workshopSessionId });
		}),
		unbindLobbyWorkshopSession: (({ commit }) => {
			commit('setBoundPropsLobbyWorkshopSession', { workshopId: null, workshopSessionId: null });
		}),
		bindLobbyWorkshopSessionQueueItem: (({ commit }, workshopSessionQueueItemId: string) => {
			commit('setBoundPropsLobbyWorkshopSessionQueueItem', { workshopSessionQueueItemId });
		}),
		unbindLobbyWorkshopSessionQueueItem: (({ commit }) => {
			commit('setBoundPropsLobbyWorkshopSessionQueueItem', { workshopSessionQueueItemId: null });
		}),
		bindAnnouncements: (({ commit }) => {
			commit('setBoundPropsAnnouncements', {});
		}),
		bindUser: (({ commit }, authUser: firebase.User) => {
			commit('setBoundPropsUser', { authUser });
		}),
		bindUserBalanceAccount: (({ commit }, user: User) => {
			commit('setBoundPropsUserBalanceAccount', { user });
		}),
		bindTeam: (({ commit }, teamId: string) => {
			commit('setBoundPropsTeam', { teamId });
		}),
		unbindTeam: (({ commit }) => {
			commit('setBoundPropsTeam', { teamId: null });
		}),
		bindTeamBalanceAccount: (({ commit }, teamId: string) => {
			commit('setBoundPropsTeamBalanceAccount', { teamId });
		}),
		unbindTeamBalanceAccount: (({ commit }) => {
			commit('setBoundPropsTeamBalanceAccount', { teamId: null });
		}),
		bindStripeCustomer: (({ commit }, user: User) => {
			commit('setBoundPropsStripeCustomer', { user });
		}),
		unbindStripeCustomer: (({ commit }) => {
			commit('setBoundPropsStripeCustomer', { user: null });
		}),
		bindStripeTeamsCustomer: (({ commit }, teamId: string) => {
			commit('setBoundPropsStripeTeamsCustomer', { teamId });
		}),
		unbindStripeTeamsCustomer: (({ commit }) => {
			commit('setBoundPropsStripeTeamsCustomer', { teamId: null });
		}),
		bindCurrSessions: (({ commit }, user: User) => {
			commit('setBoundPropsCurrSessions', { user });
		}),
		bindCurrSessionBackgroundTasks: (({ commit }, session: Session) => {
			commit('setBoundPropsCurrSessionBackgroundTasks', { session });
		}),
		bindAdminRegularSessions: (({ commit }, user: User) => {
			commit('setBoundPropsAdminRegularSessions', { user });
		}),
		bindStableDiffusionModels: (({ commit }) => {
			commit('setBoundPropsStableDiffusionModels', {});
		}),
		bindSetupSessionPrefill: (({ commit }, { authUser, appKey }) => {
			commit('setBoundPropsSetupSessionPrefill', { authUser, appKey });
		}),

		/*
		VuexFirestoreBindingsMixin - Actual binding results
		 */
		resultAppOffers: (({ commit }, payload: AppOffer[]) => {
			commit('resultAppOffers', payload);
		}),
		resultHardwareOffers: (({ commit }, payload: HardwareOffer[]) => {
			commit('resultHardwareOffers', payload);
		}),
		resultSoftwareOffers: (({ commit }, payload: SoftwareOffer[]) => {
			commit('resultSoftwareOffers', payload);
		}),
		resultExtensionOffers: (({ commit }, payload: ExtensionOffer[]) => {
			commit('resultExtensionOffers', payload);
		}),
		resultStableDiffusionOffers: (({ commit }, payload: StableDiffusionOffer[]) => {
			commit('resultStableDiffusionOffers', payload);
		}),
		resultWorkspaces: (({ commit }, payload: Workspace[]) => {
			commit('resultWorkspaces', payload);
		}),
		resultRunnits: (({ commit }, payload: Runnit[]) => {
			commit('resultRunnits', payload);
		}),
		resultRunnitNodes: (({ commit }, payload: RunnitNode[]) => {
			commit('resultRunnitNodes', payload);
		}),
		resultPublicRunnitNodeDefs: (({ commit }, payload: RunnitNodeDef[]) => {
			commit('resultPublicRunnitNodeDefs', payload);
		}),
		resultTeamRunnitNodeDefs: (({ commit }, payload: RunnitNodeDef[]) => {
			commit('resultTeamRunnitNodeDefs', payload);
		}),
		resultDraftRunnitNodeRun: (({ commit }, payload: RunnitNodeRun[]) => {
			commit('resultDraftRunnitNodeRun', payload);
		}),
		resultWorkshops: (({ commit }, payload: Workshop[]) => {
			commit('resultWorkshops', payload);
		}),
		resultWorkshopSessions: (({ commit }, payload: WorkshopSession[]) => {
			commit('resultWorkshopSessions', payload);
		}),
		resultWorkshopSessionQueueItems: (({ commit }, payload: WorkshopSessionQueueItem[]) => {
			commit('resultWorkshopSessionQueueItems', payload);
		}),
		resultLobbyWorkshop: (({ commit }, payload: Workshop) => {
			commit('resultLobbyWorkshop', payload);
		}),
		resultLobbyWorkshopSession: (({ commit }, payload: WorkshopSession) => {
			commit('resultLobbyWorkshopSession', payload);
		}),
		resultLobbyWorkshopSessionQueueItem: (({ commit }, payload: WorkshopSessionQueueItem) => {
			commit('resultLobbyWorkshopSessionQueueItem', payload);
		}),
		resultAnnouncements: (({ commit }, payload: Announcement[]) => {
			commit('resultAnnouncements', payload);
		}),
		resultUser: (({ commit }, payload: User) => {
			commit('resultUser', payload);
		}),
		resultUserBalanceAccount: (({ commit }, payload: BalanceAccount) => {
			commit('resultUserBalanceAccount', payload);
		}),
		resultTeam: (({ commit }, payload: Team) => {
			commit('resultTeam', payload);
		}),
		resultTeamBalanceAccount: (({ commit }, payload: BalanceAccount) => {
			commit('resultTeamBalanceAccount', payload);
		}),
		resultStripeCustomer: (({ commit }, payload: StripeCustomer) => {
			commit('resultStripeCustomer', payload);
		}),
		resultStripeTeamsCustomer: (({ commit }, payload: StripeCustomer) => {
			commit('resultStripeTeamsCustomer', payload);
		}),
		resultCurrSessions: (({ commit }, payload: Session[]) => {
			commit('resultCurrSessions', payload);
		}),
		resultCurrSessionBackgroundTasks: (({ commit }, payload: Session[]) => {
			commit('resultCurrSessionBackgroundTasks', payload);
		}),
		resultAdminRegularSessions: (({ commit }, payload: Session[]) => {
			commit('resultAdminRegularSessions', payload);
		}),
		resultStableDiffusionModels: (({ commit }, payload: StableDiffusionModel[]) => {
			commit('resultStableDiffusionModels', payload);
		}),
		resultSetupSessionPrefill: (({ commit }, payload: SetupSessionPrefill) => {
			commit('resultSetupSessionPrefill', payload);
		}),

		/*
		VuexFirestoreBindingsMixin - Set loading booleans for initial fetch
		 */
		setLoadingAppOffers: (({ commit }, loading: boolean) => {
			commit('setLoadingAppOffers', !!loading);
		}),
		setLoadingHardwareOffers: (({ commit }, loading: boolean) => {
			commit('setLoadingHardwareOffers', !!loading);
		}),
		setLoadingSoftwareOffers: (({ commit }, loading: boolean) => {
			commit('setLoadingSoftwareOffers', !!loading);
		}),
		setLoadingExtensionOffers: (({ commit }, loading: boolean) => {
			commit('setLoadingExtensionOffers', !!loading);
		}),
		setLoadingStableDiffusionOffers: (({ commit }, loading: boolean) => {
			commit('setLoadingStableDiffusionOffers', !!loading);
		}),
		setLoadingWorkspaces: (({ commit }, loading: boolean) => {
			commit('setLoadingWorkspaces', !!loading);
		}),
		setLoadingRunnits: (({ commit }, loading: boolean) => {
			commit('setLoadingRunnits', !!loading);
		}),
		setLoadingRunnitNodes: (({ commit }, loading: boolean) => {
			commit('setLoadingRunnitNodes', !!loading);
		}),
		setLoadingPublicRunnitNodeDefs: (({ commit }, loading: boolean) => {
			commit('setLoadingPublicRunnitNodeDefs', !!loading);
		}),
		setLoadingTeamRunnitNodeDefs: (({ commit }, loading: boolean) => {
			commit('setLoadingTeamRunnitNodeDefs', !!loading);
		}),
		setLoadingDraftRunnitNodeRun: (({ commit }, loading: boolean) => {
			commit('setLoadingDraftRunnitNodeRun', !!loading);
		}),
		setLoadingWorkshops: (({ commit }, loading: boolean) => {
			commit('setLoadingWorkshops', !!loading);
		}),
		setLoadingWorkshopSessions: (({ commit }, loading: boolean) => {
			commit('setLoadingWorkshopSessions', !!loading);
		}),
		setLoadingWorkshopSessionQueueItems: (({ commit }, loading: boolean) => {
			commit('setLoadingWorkshopSessionQueueItems', !!loading);
		}),
		setLoadingLobbyWorkshop: (({ commit }, loading: boolean) => {
			commit('setLoadingLobbyWorkshop', !!loading);
		}),
		setLoadingLobbyWorkshopSession: (({ commit }, loading: boolean) => {
			commit('setLoadingLobbyWorkshopSession', !!loading);
		}),
		setLoadingLobbyWorkshopSessionQueueItem: (({ commit }, loading: boolean) => {
			commit('setLoadingLobbyWorkshopSessionQueueItem', !!loading);
		}),
		setLoadingAnnouncements: (({ commit }, loading: boolean) => {
			commit('setLoadingAnnouncements', !!loading);
		}),
		setLoadingUser: (({ commit }, loading: boolean) => {
			commit('setLoadingUser', !!loading);
		}),
		setLoadingUserBalanceAccount: (({ commit }, loading: boolean) => {
			commit('setLoadingUserBalanceAccount', !!loading);
		}),
		setLoadingTeam: (({ commit }, loading: boolean) => {
			commit('setLoadingTeam', !!loading);
		}),
		setLoadingTeamBalanceAccount: (({ commit }, loading: boolean) => {
			commit('setLoadingTeamBalanceAccount', !!loading);
		}),
		setLoadingStripeCustomer: (({ commit }, loading: boolean) => {
			commit('setLoadingStripeCustomer', !!loading);
		}),
		setLoadingStripeTeamsCustomer: (({ commit }, loading: boolean) => {
			commit('setLoadingStripeTeamsCustomer', !!loading);
		}),
		setLoadingCurrSessions: (({ commit }, loading: boolean) => {
			commit('setLoadingCurrSessions', !!loading);
		}),
		setLoadingCurrSessionBackgroundTasks: (({ commit }, loading: boolean) => {
			commit('setLoadingCurrSessionBackgroundTasks', !!loading);
		}),
		setLoadingAdminRegularSessions: (({ commit }, loading: boolean) => {
			commit('setLoadingAdminRegularSessions', !!loading);
		}),
		setLoadingStableDiffusionModels: (({ commit }, loading: boolean) => {
			commit('setLoadingStableDiffusionModels', !!loading);
		}),
		setLoadingSetupSessionPrefill: (({ commit }, loading: boolean) => {
			commit('setLoadingSetupSessionPrefill', !!loading);
		}),

		/*
		Fetches
		 */
		fetchSbudServices: async (ctx: ActionContext<State, State>, sessionId: string): Promise<boolean> => {
			ctx.commit('setSbudServicesLoading', true);

			try {
				const proxyAdminIdToken: string = getProxyAdminIdToken();
				const functionRef = functions
					.httpsCallable('serverBuddy');
				const response = (await functionRef({
					sessionId,
					getAvailableServices: true,
					...(proxyAdminIdToken && { proxyAdminIdToken }),
				})).data;
				if (response && response.success) {
					ctx.commit('setSbudServices', {
						sessionId: sessionId,
						downloadCivitaiModels: !!response.data.downloadCivitaiModels,
						restart: !!response.data.restart,
						getLogs: !!response.data.getLogs,
						secrets: response.data.secrets || {},
					});
					return true;
				}
			} catch (e) {
				console.error(e);
			} finally {
				ctx.commit('setSbudServicesLoading', false);
			}

			return false;
		},
		loadCivitaiPreloadModel: async (ctx: ActionContext<State, State>): Promise<void> => {
			const CIVITAI_GET_MODEL_BASE_URL: string = `https://civitai.com/api/v1/models/`;
			const lastModelId: string = getCivitaiModelId();
			const lastModelVersionId: string = getCivitaiModelVersionId();
			let model: any = null;
			let modelVersion: any = null;

			if (lastModelId && lastModelVersionId) {
				try {
					const response: AxiosResponse = await axios.get(`${CIVITAI_GET_MODEL_BASE_URL}${lastModelId}`);
					if (response && response.data) {
						model = response.data || null;
						modelVersion = (
							(model.modelVersions || []).find(({ id }) => _toString(id) === _toString(lastModelVersionId))
						) || null;
					}
				} catch (e) {
					console.error(e);
				}
			}

			if (model && modelVersion) {
				const providerKey: ModelProviderKey = 'civitai';
				ctx.commit('updatePreloadModel', {
					providerKey,
					result: {
						model,
						modelVersion,
					},
				});
			}
		},
		loadModelSelectItems: async (
			ctx: ActionContext<State, State>,
			searchStr: string,
		): Promise<{ civitaiSuccess: boolean }> => {
			const search: string = _trim(searchStr) || null;
			let civitaiSuccess: boolean = true;

			try {
				const functionRef = functions
					.httpsCallable('getCivitaiModelsList');
				const { success, results } = (await functionRef({
					search,
				})).data;

				const providerKey: ModelProviderKey = 'civitai';
				ctx.commit('updateModelSelectItems', { providerKey, results: success ? results : [] });
			} catch (e) {
				console.error(e);
				civitaiSuccess = false;
			}

			return {
				civitaiSuccess,
			};
		},
		loadMorePastSessions: async (ctx: ActionContext<State, State>): Promise<void> => {
			ctx.commit('setPastSessionsLoading', true);
			const PAGE_SIZE: number = 10;

			// index created: sessions: state Ascending userId Ascending createdAt Descending __name__ Descending
			const whereParams1: [string, WhereFilterOp, any] = ['state', 'in', PAST_SESSION_STATES];
			const whereParams2: [string, WhereFilterOp, any] = ['userId', '==', ctx.state.user.id];
			const orderByParams: [string, OrderByDirection] = ['createdAt', 'desc'];
			const limit: number = PAGE_SIZE + 1;
			const sessionsRef: Query = ctx.state.pastSessionsCursor
				? (
					db.collection('sessions')
						.where(...whereParams1)
						.where(...whereParams2)
						.orderBy(...orderByParams)
						.limit(limit)
						.startAt(ctx.state.pastSessionsCursor)
				) : (
					db.collection('sessions')
						.where(...whereParams1)
						.where(...whereParams2)
						.orderBy(...orderByParams)
						.limit(limit)
				);

			const fetchedSessions: Session[] = [];
			let cursor: QueryDocumentSnapshot = null;
			(await sessionsRef.get()).forEach((snapshot: QueryDocumentSnapshot) => {
				if (fetchedSessions.length < PAGE_SIZE) {
					fetchedSessions.push({
						...snapshot.data(),
						get id () { return snapshot.id },
					} as Session);
				} else {
					cursor = snapshot;
				}
			});

			ctx.commit('setPastSessions', [
				...ctx.state.pastSessions,
				...fetchedSessions,
			]);
			ctx.commit('setPastSessionsLoading', false);
			ctx.commit('setPastSessionsCursor', cursor);
		},
		loadTeamReportEmbedUrls: async (ctx: ActionContext<State, State>, payload: { team: Team, company: Company }): Promise<void> => {
			ctx.commit('setTeamReportEmbedUrlsDataLoading', true);
			const onError: Function = (e) => {
				console.error('Error loading the embedUrls: ', e);
			};

			try {
				const {
					team,
					company,
				} = payload;

				const functionRef = functions
					.httpsCallable('getTeamReportEmbedUrls');
				const { success, iframeUrls } = (await functionRef({
					teamId: team ? team.id : null,
					companyId: _get(company, 'id') || null,
				})).data;

				if (!success) {
					onError(new Error('failed to load team report embedUrls'));
				} else {
					ctx.commit('setTeamReportEmbedUrlsData', {
						...ctx.state.teamReportEmbedUrlsData,
						...iframeUrls,
					});
					ctx.commit('setTeamReportEmbedUrlsDataLoading', false);
				}
			} catch (e) {
				onError(e);
			}
		},

		/*
		Auth
		 */
		updateAuthEmail: async (ctx: ActionContext<State, State>, email: string): Promise<{ success: boolean, showReauthenticateDialog: boolean }> => {
			const processedEmail: string = _trim(email).toLowerCase();
			const authUser: firebase.User = firebase.auth().currentUser;
			try {
				await authUser.updateEmail(processedEmail);

				ctx.commit('updateSnackbar', {
					status: SNACKBAR_STATUS.SUCCESS,
					message: 'Success! Email updated.',
					show: true,
				} as SnackbarState);
				return {
					success: true,
					showReauthenticateDialog: false,
				};
			} catch (error: any) {
				const errorCode: string = error.code;
				console.error('Error updating login email: ', error);

				let message: string = 'Error updating email address, try again.';
				let showReauthenticateDialog: boolean = false;
				if (errorCode === 'auth/email-already-in-use') {
					message = 'Email already in use';
				} else if (errorCode === 'auth/requires-recent-login') {
					showReauthenticateDialog = true;
				}

				if (!showReauthenticateDialog) {
					ctx.commit('updateSnackbar', {
						status: SNACKBAR_STATUS.ERROR,
						message,
						show: true,
					} as SnackbarState);
				}
				return {
					success: false,
					showReauthenticateDialog,
				};
			}
		},

		/*
		Creates
		 */
		purchaseSession: async (
			ctx: ActionContext<State, State>,
			{
				stableDiffusionOffer,
				apps,
				backendApps,
				hardware,
				software,
				options,
				apiEnabled,
				persistExtensions,
				preloadModel,
				purchaseMins,
				stableDiffusionModel,
				displayName,
				isTeamSession,
				workspaceId,
				region,
				workshopSessionData,
			}: {
				stableDiffusionOffer?: StableDiffusionOffer
				apps: AppKey[]
				backendApps: AppKey[]
				hardware: HardwareKey
				software: SoftwareKey
				options: SessionOptions
				apiEnabled: boolean
				persistExtensions: boolean
				preloadModel: { provider: ModelProviderKey, modelId: string, modelVersionId: string, downloadUrl: string }
				purchaseMins: number
				stableDiffusionModel: StableDiffusionModel
				displayName: string
				isTeamSession: boolean
				workspaceId: string
				region: Region
				workshopSessionData?: {
					workshopId: string
					workshopSessionId: string
					workshopSessionQueueItemId: string
					userId: string
					userEmail: string
				},
			},
		): Promise<{
			success: boolean,
			sessionUuid: string,
		}> => {
			try {
				const nowDate: Date = new Date();
				const userRef: DocumentReference = workshopSessionData
					? db.doc(`users/${workshopSessionData.userId}`)
					: db.doc(`users/${ctx.state.user.id}`);
				const userEmail: string = workshopSessionData
					? workshopSessionData.userEmail
					: ctx.state.user.email;
				const teamRef: DocumentReference = isTeamSession ? db.doc(`teams/${ctx.state.team.id}`) : null;
				const workspaceRef: DocumentReference = workspaceId ? db.doc(`workspaces/${workspaceId}`) : null;
				const balanceAccountRef: DocumentReference = db.doc(`balanceAccounts/${isTeamSession ? ctx.state.team.id : ctx.state.user.id}`);
				const stableDiffusionOfferRef: DocumentReference = stableDiffusionOffer ? db.doc(`stableDiffusionOffers/${stableDiffusionOffer.id}`) : null;
				const stableDiffusionModelRef: DocumentReference = stableDiffusionModel ? db.doc(`stableDiffusionModels/${stableDiffusionModel.id}`) : null;

				const sessionUuid: string = nanoIdLowerNum(9);
				await db.collection('sessions')
					.add({
						type: SESSION_TYPE.RD,
						createdAt: nowDate,
						purchaseAt: nowDate,
						purchaseMins,
						state: SESSION_STATE.PURCHASE,
						uuid: sessionUuid,
						userId: userRef.id,
						user: userRef,
						email: userEmail,
						...(isTeamSession && {
							teamId: ctx.state.team.id,
							team: teamRef,
						}),
						...(workspaceId && {
							workspaceId: workspaceId,
							workspace: workspaceRef,
						}),
						balanceAccount: balanceAccountRef,
						apps,
						backendApps,
						hardware,
						software,
						...(!_isEmpty(options) && {
							options
						}),
						apiEnabled,
						persistExtensions,
						preloadModel,
						displayName,
						...(stableDiffusionOfferRef && {
							stableDiffusionOffer: stableDiffusionOfferRef,
						}),
						...(stableDiffusionModelRef && {
							stableDiffusionModel: stableDiffusionModelRef,
						}),
						region,
						...(workshopSessionData && {
							workshopId: workshopSessionData.workshopId,
							workshopSessionId: workshopSessionData.workshopSessionId,
							workshopSessionQueueItemId: workshopSessionData.workshopSessionQueueItemId,
						}),
					});
				return {
					success: true,
					sessionUuid,
				};
			} catch (e) {
				console.error('Error purchasing session: ', e);
				ctx.commit('updateSnackbar', {
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error purchasing session, please reach out to report issues by clicking the support button in our top toolbar',
					show: true,
				} as SnackbarState);
				return {
					success: true,
					sessionUuid: null,
				};
			}
		},

		/*
		Updates
		 */
		updateSessionDetails: async (
			ctx: ActionContext<State, State>,
			{ sessionId, updateFields }: { sessionId: string, updateFields: Partial<Session> },
		): Promise<{ success: boolean }> => {
			try {
				const sessionRef: DocumentReference = db.doc(`sessions/${sessionId}`);
				await sessionRef
					.update({
						...updateFields,
					});
				ctx.commit('updateSnackbar', {
					status: SNACKBAR_STATUS.SUCCESS,
					message: 'Success! Session updated.',
					show: true,
				} as SnackbarState);
				return { success: true };
			} catch (e) {
				console.error('Error updating session details: ', e);
				ctx.commit('updateSnackbar', {
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error saving session',
					show: true,
				} as SnackbarState);
				return { success: false };
			}
		},
		extendSession: async (
			ctx: ActionContext<State, State>,
			{ session, extendMins }: { session: Session, extendMins: number },
		): Promise<{ success: boolean }> => {
			const onError: Function = (e) => {
				console.error('Error extending session: ', e);
				ctx.commit('updateSnackbar', {
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error extending session, please reach out to report issues by clicking the support button in our top toolbar',
					show: true,
				} as SnackbarState);
			};

			try {
				const functionRef = functions
					.httpsCallable('setExtendSession');
				const { success } = (await functionRef({
					sessionId: session.id,
					extendMins,
				})).data;
				if (!success) {
					onError(new Error('setExtendSession returned success: false'));
				}

				return { success };
			} catch (e) {
				onError(e);
				return { success: false };
			}
		},
		stopSession: async (ctx: ActionContext<State, State>, session: Session): Promise<{ success: boolean }> => {
			const onError: Function = (e) => {
				console.error('Error stopping session: ', e);
				ctx.commit('updateSnackbar', {
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error stopping session, please reach out to report issues by clicking the support button in our top toolbar',
					show: true,
				} as SnackbarState);
			};

			try {
				const functionRef = functions
					.httpsCallable('setStopSession');
				const { success } = (await functionRef({
					sessionId: session.id,
				})).data;
				if (!success) {
					onError(new Error('setStopSession returned success: false'));
				}

				return { success };
			} catch (e) {
				onError(e);
				return { success: false };
			}
		},

		/*
		Other actions
		 */
		goToStripeSubscriptionPortal: async (ctx: ActionContext<State, State>, team: Team): Promise<{ success: boolean }> => {
			if (
				(
					!team && (
						!ctx.state.user ||
						!ctx.state.stripeCustomer ||
						!(
							ctx.state.user.clubOffer &&
							typeof ctx.state.user.clubOffer !== 'string' &&
							!ctx.state.user.clubOffer.isFreemium
						)
					)
				) ||
				(
					team && (
						!ctx.state.user ||
						!ctx.state.stripeTeamsCustomer
					)
				)
			) {
				return { success: false };
			}
			try {
				ctx.commit('updateLoader', {
					show: true,
					message: team ? 'Loading team subscription page...' : 'Loading club membership page...',
				});

				const functionRef = functions
					.httpsCallable('createStripePortalLink');
				const { url } = (await functionRef({
					teamId: team ? team.id : null,
				})).data;
				if (url) {
					window.location.href = url;
					return { success: true };
				} else {
					ctx.commit('updateSnackbar', {
						status: SNACKBAR_STATUS.ERROR,
						message: 'Error loading payment account portal, please reach out to report issues by clicking the support button in our top toolbar',
						show: true,
					});
				}
			} catch (e) {
				console.error(e);
				ctx.commit('updateLoader', {
					show: false,
				});
				ctx.commit('updateSnackbar', {
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading payment account portal, please reach out to report issues by clicking the support button in our top toolbar',
					show: true,
				});
				return { success: false };
			}
		},

		/*
		Mutating store
		 */
		setAuthUser ({ commit }, payload: firebase.User) {
			commit('setAuthUser', payload);
		},
		setAuthUserCredential ({ commit }, payload: firebase.auth.UserCredential) {
			commit('setAuthUserCredential', payload);
		},
		setAdminUserProxyTokens ({ commit }, payload: { proxyAdminToken: string, proxyAdminIdToken: string }) {
			commit('setAdminUserProxyTokens', payload);
		},
		updateLoginQueryParamsData ({ commit }, payload: Partial<LoginQueryParamsDataState>) {
			commit('updateLoginQueryParamsData', payload);
		},
		updateAppQueryParamsData ({ commit }, payload: Partial<AppQueryParamsDataState>) {
			commit('updateAppQueryParamsData', payload);
		},
		setTeamReportEmbedUrlsData ({ commit }, payload: Partial<TeamReportEmbedUrlsDataState>) {
			commit('setTeamReportEmbedUrlsData', payload);
		},
		setTeamReportEmbedUrlsLoading ({ commit }, payload: Partial<TeamReportEmbedUrlsDataState>) {
			commit('setTeamReportEmbedUrlsLoading', payload);
		},
		updateUpsellDialog ({ commit }, payload: Partial<UpsellDialogDataState>) {
			commit('updateUpsellDialog', payload);
		},
		updateRunnitState ({ commit }, payload: Partial<RunnitState>) {
			commit('updateRunnitState', payload);
		},
		updateToolbar ({ commit }, payload: Partial<ToolbarState>) {
			commit('updateToolbar', payload);
		},
		updateLoader ({ commit }, payload: Partial<LoaderState>) {
			commit('updateLoader', payload);
		},
		updateSnackbar ({ commit }, payload: Partial<SnackbarState>) {
			commit('updateSnackbar', payload);
		},
		updatePreloadModel ({ commit }, { providerKey, result }) {
			commit('updatePreloadModel', { providerKey, result });
		},
		setStableDiffusionModel ({ commit }, payload: StableDiffusionModel) {
			commit('setStableDiffusionModel', payload);
		},
		setFingerprintResult ({ commit }, payload: FingerprintResult) {
			commit('setFingerprintResult', payload);
		},
		tickRealTimeMoment ({ commit }) {
			commit('tickRealTimeMoment');
		},
		setSessionSoundsOff ({ commit }, payload: boolean) {
			commit('setSessionSoundsOff', payload);
		},
		incrementReloadSessionUiIframeTrigger ({ commit }) {
			commit('incrementReloadSessionUiIframeTrigger');
		},
		incrementResetExtensionOffersTrigger ({ commit }) {
			commit('incrementResetExtensionOffersTrigger');
		},
		incrementFilterExtensionOffersTrigger ({ commit }) {
			commit('incrementFilterExtensionOffersTrigger');
		},
		incrementImageZoomResetTrigger ({ commit }) {
			commit('incrementImageZoomResetTrigger');
		},
		incrementFireInitialPromptGenerationTrigger ({ commit }) {
			commit('incrementFireInitialPromptGenerationTrigger');
		},
		incrementScreenResizeTrigger ({ commit }) {
			commit('incrementScreenResizeTrigger');
		},
	},

	modules: {},
});
