
import Vue from 'vue';
import BaseButton from '@/components/base/BaseButton.vue';
import GlassButton from '@/components/base/GlassButton.vue';
import RunnitSubscriptionLimitSelect from '@/views/Runnits/pages/RunnitsSubscriptionsPage/RunnitSubscriptionLimitSelect.vue';
import TokensSVG from '@/assets/TokensSVG.vue';

export default Vue.extend({
	name: 'RunnitsSubscriptionCard',
	props: {
		club: {
			type: Object,
			required: true,
		},
		isMonthly: {
			type: Boolean,
			required: true,
		},
	},
	methods: {
		getDollars (amount) {
			return +`${amount}`.slice(0, -2);
		},
		getCents (amount) {
			return +`${amount}`.slice(-2);
		},
		handleCtaClick (club) {
			this.$emit('cta-click', club);
		},
		getComputedPrice (club, isMonthly) {
			return isMonthly ? club.monthlyPrice : club.yearlyPrice;
		},
		handleLimitSelected (limit) {
			this.$emit('limit-selected', { club: this.club, limit });
		},
	},
	components: {
		TokensSVG,
		BaseButton,
		GlassButton,
		RunnitSubscriptionLimitSelect,
	},
});
