import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('runnit-menu-or-bottom-sheet',{attrs:{"title":"Inpainting Editor","icon":"mdi-draw","max-width":675,"min-width":675,"max-height":_vm.isExpandedMode ? 900 : 703,"min-height":_vm.isExpandedMode ? 900 : 703,"nudge-bottom":70,"nudge-left":1190,"value":_vm.runnitState.inpaintingEditorOpen,"bottom-sheet-on-medium":"","is-bottom-sheet":_vm.isExpandedMode},on:{"on-close":_vm.onClose},scopedSlots:_vm._u([{key:"trigger",fn:function({ on }){return [_vm._t("trigger",null,{"on":on})]}},{key:"background",fn:function(){return [_c('complex-background-setting-menu2',{attrs:{"top":_vm.$vuetify.breakpoint.xsOnly ? -170 : -250}})]},proxy:true},{key:"top-right",fn:function(){return [(_vm.$vuetify.breakpoint.lgAndUp)?_c(VTooltip,{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('base-button',_vm._g(_vm._b({staticStyle:{"margin":"0 15px 0 0"},attrs:{"icon":"","text":""},on:{"click":_vm.handleExpandedClick}},'base-button',attrs,false),on),[(_vm.isExpandedMode)?_c(VIcon,{staticStyle:{"color":"rgba(255, 255, 255, 0.3)","font-size":"30px"}},[_vm._v(" mdi-image-size-select-small ")]):_c(VIcon,{staticStyle:{"color":"rgba(255, 255, 255, 0.3)","font-size":"30px"}},[_vm._v(" mdi-arrow-expand-all ")])],1)]}}],null,false,564305969)},[_c('div',{staticClass:"tooltip-max-width"},[(_vm.isExpandedMode)?_c('span',[_vm._v(" Go back to inline editor mode ")]):_c('span',[_vm._v(" Expand the editor to fill the screen ")])])]):_vm._e()]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"inpainting-editor",on:{"dragover":function($event){$event.preventDefault();return _vm.dragOver.apply(null, arguments)},"drop":function($event){$event.stopPropagation();$event.preventDefault();return _vm.actionsCardDragEnd.apply(null, arguments)}}},[_c('div',{staticClass:"inpainting-editor-canvas",style:({
						margin: _vm.$vuetify.breakpoint.mdAndDown ? '15px' : '30px 30px 30px 30px',
					})},[_c('canvas',{staticStyle:{"border-radius":"8px","position":"absolute","top":"0","left":"0"},style:({
							width: _vm.canvasWidth,
							height: _vm.canvasHeight,
						}),attrs:{"id":"background-canvas"}}),_c('canvas',{staticStyle:{"border-radius":"8px","position":"absolute","top":"0","left":"0","border":"2px solid var(--dark-purple-900)"},style:({
							width: _vm.canvasWidth,
							height: _vm.canvasHeight,
						}),attrs:{"id":"brush-canvas"}})]),_c('div',{staticClass:"inpainting-editor-actions",class:{
						grabbable: _vm.isExpandedMode,
					},attrs:{"id":"actionsCardDraggable","draggable":_vm.isExpandedMode},on:{"dragstart":_vm.actionsCardDragStart}},[_c('div',{staticClass:"inpainting-editor-actions-top"},[_c('div',{staticClass:"d-flex align-center"},[_c('base-slider',{staticStyle:{"min-width":"200px","margin":"0 5px 0 10px"},attrs:{"max":80,"min":1,"hide-details":"","value-class":"brush-size"},model:{value:(_vm.brushSize),callback:function ($$v) {_vm.brushSize=$$v},expression:"brushSize"}}),_c('glass-button',{staticClass:"mr-2",attrs:{"small":""},on:{"click":_vm.onStepBack}},[_c(VIcon,{staticStyle:{"margin":"0 0 0 1px","font-size":"16px"}},[_vm._v(" mdi-arrow-left-top-bold ")])],1)],1),_c('div',{staticClass:"d-flex"},[_c('glass-button',{staticClass:"mr-2",attrs:{"small":""},on:{"click":_vm.onDeleteInpaintingMask}},[_c(VIcon,{staticStyle:{"margin":"0 0 0 1px","font-size":"16px"}},[_vm._v(" mdi-trash-can ")])],1)],1)]),_c('div',{staticClass:"inpainting-editor-actions-bottom"},[_c('base-button',{staticStyle:{"min-width":"90px"},attrs:{"super-primary":"","small":"","disabled":_vm.runnitState.inpaintingEditorSaving,"loading":_vm.runnitState.inpaintingEditorSaving},on:{"click":_vm.handleDone}},[_vm._v(" Done ")])],1)])])]},proxy:true}],null,true)}),_c('img',{ref:"imageRef",staticStyle:{"visibility":"hidden"},attrs:{"src":_vm.runnitState.inpaintingBackgroundImageUrl},on:{"load":_vm.imageLoaded}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }