
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { STORAGE_OPTION } from '@/constants/enums';
import { SELECTED_STORAGE_TYPE, UPSELL_TYPE } from '@/constants/constants';
import WorkspaceCard from '@/views/Workspaces/WorkspaceCard.vue';
import BorderSelectCard from '@/components/BorderSelectCard.vue';
import BorderCardUpsell from '@/components/BorderCardUpsell.vue';
import StorageOptionCard from '@/views/Sessions/StorageOptionsList/StorageOptionCard.vue';

export default Vue.extend({
	name: 'StorageOptionsList',
	props: {
		selectedStorageType: { type: String, default: null },
		selectedWorkspaceId: { type: String, default: null },
	},
	data () {
		return {
			UPSELL_TYPE,
			STORAGE_OPTION,
			SELECTED_STORAGE_TYPE,
			selectedStorageOption: STORAGE_OPTION.PUBLIC,
		};
	},
	computed: {
		...mapState([
			'user',
			'workspaces',
		]),
		...mapGetters([
			'clubOfferBenefits',
		]),
		items () {
			return [
				...(!this.clubOfferBenefits.isCc ? [{
					value: STORAGE_OPTION.PUBLIC,
					label: 'Pay As You Go Storage',
					icon: 'mdi-timer-outline',
					descriptionHtml: '<div>(72 hour retention)</div>',
				}] : []),
				{
					value: STORAGE_OPTION.PRIVATE,
					label: 'Creator\'s Club Personal Storage',
					icon: 'mdi-star-box-outline',
					descriptionHtml: '<div>(100 GB forever)</div>',
				},
			];
		},
	},
	watch: {
		user: {
			immediate: true,
			handler () {
				if (this.clubOfferBenefits.isCc) {
					this.selectedStorageOption = STORAGE_OPTION.PRIVATE;
				} else {
					this.selectedStorageOption = STORAGE_OPTION.PUBLIC;
				}
			},
		},
	},
	methods: {
		...mapActions([
			'updateUpsellDialog',
		]),
		doUpsellToCreatorClub () {
			this.updateUpsellDialog({ creatorsClub: true });
		},
		onSelectStorageOption (storageOptionValue: string) {
			if (
				(!this.clubOfferBenefits.isCc && storageOptionValue === STORAGE_OPTION.PUBLIC) ||
				(this.clubOfferBenefits.isCc && storageOptionValue === STORAGE_OPTION.PRIVATE)
			) {
				this.selectedStorageOption = storageOptionValue;
				this.$emit('on-select-storage-option', storageOptionValue);
			}
		},
	},
	components: {
		BorderCardUpsell,
		BorderSelectCard,
		WorkspaceCard,
		StorageOptionCard,
	},
});
