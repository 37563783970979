
import Vue from 'vue';
import { storage } from '@/firebase';
import { RunnitMask } from '@run-diffusion/shared';

export default Vue.extend({
	name: 'ImageInfoMaskPreview',
	props: {
		mask: { type: Object, required: true },
		showMask: { type: Boolean, default: false },
		width: { type: String, default: null },
		height: { type: String, default: null },
	},
	data () {
		return {
			maskUrl: null,
		};
	},
	watch: {
		mask: {
			immediate: true,
			async handler (newVal: RunnitMask, oldVal: RunnitMask) {
				const newMaskName: string = this._get(newVal, 'name') || null;
				const oldMaskName: string = this._get(oldVal, 'name') || null;
				if (newMaskName && newMaskName !== oldMaskName) {
					const maskImageRef = storage.ref(`runnitMasks/users/${newVal.userId}/masks/${newMaskName}`);

					try {
						// Exists
						this.maskUrl = await maskImageRef.getDownloadURL();
					} catch (err) {
						// doesn't exist
					}
				} else {
					this.maskUrl = null;
				}
			}
		} ,
	},
});
