"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRunnitMaskFilePath = exports.getAvatarFilePath = exports.getRunnitUploadFilePath = exports.getRunnitNodeRunResultFilePath = exports.AVATAR_SIZE = exports.THUMB_SIZE = void 0;
const lodash_1 = require("lodash");
exports.THUMB_SIZE = 512;
exports.AVATAR_SIZE = 256;
const getRunnitNodeRunResultFilePath = (nodeRunResult) => {
    const filePathPrefix = nodeRunResult.teamId
        ? `runnitResults/teams/${nodeRunResult.teamId}/runs/${nodeRunResult.nodeRunId}/`
        : `runnitResults/users/${nodeRunResult.userId}/runs/${nodeRunResult.nodeRunId}/`;
    const fileName = (0, lodash_1.get)(nodeRunResult, 'file.name', '');
    const filePath = `${filePathPrefix}${fileName}`;
    const thumbFileEnding = `thumbs/${fileName.replace(/\.[^/.]+$/, `_${exports.THUMB_SIZE}x${exports.THUMB_SIZE}.webp`)}`;
    const thumbFilePath = `${filePathPrefix}${thumbFileEnding}`;
    return { filePath, thumbFilePath };
};
exports.getRunnitNodeRunResultFilePath = getRunnitNodeRunResultFilePath;
const getRunnitUploadFilePath = (upload) => {
    const filePathPrefix = upload.teamId
        ? `runnitUploads/teams/${upload.teamId}/uploads/`
        : `runnitUploads/users/${upload.userId}/uploads/`;
    const fileName = upload.name;
    const filePath = `${filePathPrefix}${fileName}`;
    const thumbFileEnding = `thumbs/${fileName.replace(/\.[^/.]+$/, `_${exports.THUMB_SIZE}x${exports.THUMB_SIZE}.webp`)}`;
    const thumbFilePath = `${filePathPrefix}${thumbFileEnding}`;
    return { filePath, thumbFilePath };
};
exports.getRunnitUploadFilePath = getRunnitUploadFilePath;
const getAvatarFilePath = (avatar) => {
    const filePathPrefix = avatar.teamId
        ? `avatars/teams/${avatar.teamId}/uploads/`
        : `avatars/users/${avatar.userId}/uploads/`;
    const fileName = avatar.name;
    const filePath = `${filePathPrefix}${fileName}`;
    const thumbFileEnding = `thumbs/${fileName.replace(/\.[^/.]+$/, `_${exports.AVATAR_SIZE}x${exports.AVATAR_SIZE}.webp`)}`;
    const thumbFilePath = `${filePathPrefix}${thumbFileEnding}`;
    return { filePath, thumbFilePath };
};
exports.getAvatarFilePath = getAvatarFilePath;
const getRunnitMaskFilePath = (mask) => {
    const filePathPrefix = mask.teamId
        ? `runnitMasks/teams/${mask.teamId}/masks/`
        : `runnitMasks/users/${mask.userId}/masks/`;
    const fileName = mask.name;
    const filePath = `${filePathPrefix}${fileName}`;
    return { filePath };
};
exports.getRunnitMaskFilePath = getRunnitMaskFilePath;
