<template>
	<v-container
		v-if="(
			!loadingUser &&
			!loadingTeam &&
			!_get(team, 'isActive')
		)"
	>
		<v-row>
			<v-col
				v-if="!clubOfferBenefits.isCc"
				cols="12"
				lg="6"
				sm="12"
				style="padding-bottom: 0;"
			>
				<div class="d-flex align-center">
					<div class="cc-teaser-copy">
						Try out <span class="gradient-text">Creator's Club</span>?
					</div>
					<base-button
						secondary
						class="ml-6"
						style="margin: 6px 0 10px 0;"
						@click="updateUpsellDialog({ creatorsClub: true })"
					>
						Learn More
					</base-button>
				</div>
			</v-col>
			<v-col
				cols="12"
				:lg="!clubOfferBenefits.isCc ? '6' : '12'"
				sm="12"
				style="padding-bottom: 0;"
			>
				<div
					class="d-flex align-center"
					:style="{
						'justify-content': (
							!clubOfferBenefits.isCc &&
							$vuetify.breakpoint.lgAndUp
						)
							? 'flex-end'
							: 'flex-start',
						'padding': (
							!clubOfferBenefits.isCc &&
							$vuetify.breakpoint.lgAndUp
						)
							? '0 0 0 10px'
							: '0 0 0 2px',
					}"
				>
					<div class="team-teaser-copy">
						Try out <span class="gradient-text">Team Plan</span>?
					</div>
					<base-button
						secondary
						class="ml-6"
						style="margin: 6px 0 10px 0;"
						@click="goToTeamRoute"
					>
						Learn More
					</base-button>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { ROUTER } from '@/router/constants';

export default Vue.extend({
	name: 'CreatorsClubPromoTeaser',
	computed: {
		...mapState([
			'team',
			'loadingUser',
			'loadingTeam',
		]),
		...mapGetters([
			'clubOfferBenefits',
		]),
	},
	methods: {
		...mapActions([
			'updateUpsellDialog',
		]),
		goToTeamRoute () {
			this.routerPush(this.$route, this.$router, { name: ROUTER.TEAM_MEMBERS });
		},
	},
});
</script>

<style scoped lang="scss">
	.cc-teaser {
		padding: 0 0 0 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&-copy {
			padding: 10px 0 0 0;
			color: white;
			font-size: 28px;
			font-family: "danielbd", Helvetica, serif;
		}
	}
	.team-teaser-copy {
		color: white;
		font-size: 30px;
		font-family: 'nasalization', Helvetica, serif;
	}
</style>
