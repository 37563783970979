import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VAppBar,_vm._b({staticClass:"appBar",style:({
			height: _vm.APP_BAR_HEIGHT,
		}),attrs:{"app":"","fixed":"","clipped-right":""}},'v-app-bar',{
			...(_vm.isAdminProxyingUser && {
				color: 'rgb(95, 6, 6)',
			}),
		},false),[_c(VExpandTransition,[((
					!_vm.toolbar.sessionBarOpen &&
					_vm.toolbar.session
				))?_c('base-button',{staticStyle:{"position":"relative","left":"-5px"},attrs:{"icon":"","text":"","fab":"","small":""},on:{"click":_vm.onOpenSessionBar}},[_c(VIcon,{staticStyle:{"color":"var(--blue-grey-200)"}},[_vm._v("mdi-menu")])],1):_vm._e()],1),(_vm.isSessionBootingOrLaunched && _vm.$vuetify.breakpoint.smAndUp)?_c('session-info-card',{staticClass:"py-1 pl-0 pr-3",attrs:{"show-logo":"","show-type":"","show-app":!_vm.toolbar.session.teamId,"show-hardware":!_vm.toolbar.session.teamId,"show-team":"","show-workspace":"","type-font-size":"12px","app-font-size":"12px","software-font-size":"11px","hardware-font-size":"11px","team-font-size":"11px","workspace-font-size":"11px","x-small-icons":"","border-radius":"12px","min-width":"fit-content"}}):_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticClass:"full-height",class:{
					'clickable': !_vm.$route.meta.isSessionPage,
				},style:({
					...(hover && !_vm.$route.meta.isSessionPage && {
						'background-color': 'var(--grey-825)',
						'border-radius': '2px',
					}),
				}),on:{"click":_vm.onLogoClick}},[(_vm.$vuetify.breakpoint.smAndUp)?[_c('img',{staticClass:"logo",attrs:{"alt":"Logo","height":"70px","src":require("../../assets/rd-logo-white.png")}})]:[_c('img',{staticClass:"logo-mobile",attrs:{"alt":"Logo","height":"70px","src":require("../../assets/rd-logo-white-only.png")}})]],2)]}}])}),_c('div',{staticClass:"d-flex justify-center full-width"},[((
					_vm.user &&
					!_vm.isSessionLaunched &&
					!_vm.hasSessionRunning &&
					!_vm.$vuetify.breakpoint.xsOnly
				))?[((
						!_vm.user.discordUser ||
						_vm.clubOfferBenefits.isCc
					))?_c('join-our-discord',{style:({
						'left': _vm.$vuetify.breakpoint.xsOnly ? '-15px' : '0',
					}),attrs:{"breakpoint-at-md":""}}):_c('join-creators-club',{style:({
						'left': _vm.$vuetify.breakpoint.xsOnly ? '-15px' : '0',
					}),attrs:{"breakpoint-at-md":""}})]:_vm._e(),(_vm.isSessionLaunched)?_c('session-timer',{class:{
					'mr-5': !_vm.$vuetify.breakpoint.xsOnly,
				},attrs:{"hide-btns-if-is-launched-workshop-session-as-attendee":""},scopedSlots:_vm._u([{key:"btns",fn:function(){return [_c('session-timer-btns')]},proxy:true}],null,false,2649552177)}):(_vm.toolbar.session && (_vm.user.isAdmin || _vm.isAdminProxyingUser))?_c('admin-session-stats',{staticClass:"ml-2",attrs:{"session":_vm.toolbar.session}}):_vm._e()],2),((
				_vm.$vuetify.breakpoint.mdAndUp &&
				_vm.isSessionLaunched
			))?_c('get-support-btn',{staticClass:"mx-3",attrs:{"is-promo-button":""}},[_vm._v(" Learn / Get Help ")]):_vm._e(),(_vm.newUserPromotionIsLive)?_c('new-user-login-promo-dialog',{staticClass:"ml-3",scopedSlots:_vm._u([{key:"activator",fn:function({ open }){return [_c('base-button',{class:{
						'px-5': _vm.$vuetify.breakpoint.smAndUp,
					},attrs:{"small":""},on:{"click":open}},[_c(VIcon,{attrs:{"left":_vm.$vuetify.breakpoint.smAndUp}},[_vm._v("mdi-bullhorn-outline")]),_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.smAndUp ? 'NEW USER PROMO' : '')+" ")],1)]}}],null,false,2017806980)}):_vm._e(),((
				_vm.$vuetify.breakpoint.mdAndUp &&
				_vm.isSessionLaunched
			))?[(_vm.allowFileBrowserSidePanel)?_c('file-browser-btn',{key:"file-browser-btn"}):_vm._e(),(_vm.allowFileBrowserSidePanel)?_c('file-browser-new-tab-btn',{key:"file-browser-new-tab-btn"}):_vm._e(),(_vm.isSessionApiEnabled)?_c('enabled-api-animation'):_vm._e(),_c('notifications-btn',{key:"notifications-btn"}),_c('account-menu-btn',{key:"account-menu-btn"})]:((
				_vm.$vuetify.breakpoint.smAndDown &&
				_vm.isSessionLaunched
			))?[(_vm.isSessionApiEnabled)?_c('enabled-api-animation'):_vm._e(),_c(VTooltip,{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticStyle:{"color":"var(--blue-grey-200)"},attrs:{"icon":""},on:{"click":_vm.openMobileMenu}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-menu")])],1)]}}])},[_c('div',{staticClass:"tooltip-max-width"},[_vm._v("Session menu")])])]:[(_vm.isRunnitsHomeRoute)?_c('runnit-account-selection-dialog',{staticStyle:{"margin":"0 17px 0 0"}}):_vm._e(),_c('get-support-btn'),_c('notifications-btn',{key:"notifications-btn"}),_c('account-menu-btn',{key:"account-menu-btn"})]],2),(_vm.showAnnouncementsBanner)?_c('div',{staticClass:"d-flex flex-column"},_vm._l((_vm.bannerAnnouncements),function(announcement){return _c('announcements-banner',{key:announcement.id,attrs:{"announcement":announcement}})}),1):_vm._e(),_c('failed-session-message-dialogs')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }