
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { EraserBrush } from '@erase2d/fabric'; // It looks like erasing isn't part of the default build so we'd need to host our own fabric http://fabricjs.com/erasing
import { Canvas, Image as FabricImage, PencilBrush, Object as FabricObject, filters } from 'fabric';
import { getRunnitsInpaintingIsExpanded, setRunnitsInpaintingIsExpanded } from '@/utils';
import BaseSlider from '@/components/base/BaseSlider.vue';
import GlassButton from '@/components/base/GlassButton.vue';
import RunnitMenuOrBottomSheet from '@/views/Runnits/base/RunnitMenuOrBottomSheet.vue';
import ComplexBackgroundSettingMenu2 from '@/components/designElements/ComplexBackgroundSettingMenus2.vue';
import { SNACKBAR_STATUS } from '@/constants/constants';
import { RunnitMask } from '@run-diffusion/shared';
import { db, storage } from '@/firebase';
import { RUNNIT_MASK_USE_CASE, UPLOAD_FILE_TYPE } from '@/constants/enums';
import { v4 as uuidv4 } from 'uuid';

export default Vue.extend({
	name: 'InpaintingEditor',
	props: {
		maskURL: { type: String, default: null },
		nodeId: { type: String, default: null },
	},
	data () {
		return {
			SELECTION: {
				BRUSH: 'BRUSH',
				ERASER: 'ERASER',
			},
			STATIC_HEIGHT: 462,
			STATIC_WIDTH: 462,
			MOBILE_STATIC_HEIGHT: 300,
			MOBILE_STATIC_WIDTH: 300,
			brushSize: 30,
			selection: 'BRUSH',
			canvas: null as Canvas | null,
			history: [] as FabricObject[],
			backgroundImage: null,
			eraserBrush: null as EraserBrush | null,
			pencilBrush: null as PencilBrush | null,

			isExpandedMode: false,
			hasResizeListener: false,
			imageWidth: 0,
			imageHeight: 0,
			canvasWidth: 462,
			canvasHeight: 462,
		};
	},
	created () {
		this.isExpandedMode = getRunnitsInpaintingIsExpanded();
	},
	computed: {
		...mapState([
			'runnitState',
			'user',
		]),
	},
	watch: {
		isExpandedMode: {
			immediate: true,
			async handler(newVal: boolean) {
				this.$nextTick(() => {
					this.initCanvas();
				});
			},
		},
		brushSize: {
			handler(newSize: number) {
				if (this.canvas) {
					this.canvas.freeDrawingBrush.width = newSize;
				}
			},
		},
		'runnitState.inpaintingEditorOpen': {
			immediate: true,
			async handler(newVal) {
				if (newVal) {
					this.initCanvas();
				}
			}
		}
	},
	methods: {
		...mapActions([
			'updateRunnitState',
			'updateSnackbar',
		]),
		handleExpandedClick () {
			this.isExpandedMode = !this.isExpandedMode;
			setRunnitsInpaintingIsExpanded(this.isExpandedMode);
		},
		initCanvas () {
			this.destroyCanvas();

			this.setCanvasHeight();
			this.setCanvasWidth();

			this.canvas = new Canvas('brush-canvas', {
				width: this.canvasWidth,
				height: this.canvasHeight,
				isDrawingMode: true,
			});

			FabricImage.fromURL(
				this.runnitState.inpaintingBackgroundImageUrl,
				{
					crossOrigin: 'anonymous',
				}
			).then((img) => {
				if (this.canvas) {
					if (this.isLandscape()) {
						img.scaleToWidth(this.canvasWidth)
					} else {
						img.scaleToHeight(this.canvasHeight)
					}
					this.canvas.add(img);
					this.canvas.renderAll();
					this.backgroundImage = img;

					if (this.maskURL) {
						FabricImage.fromURL(
							this.maskURL,
							{
								crossOrigin: 'anonymous',
							}
						).then((img) => {
							img.filters.push(new filters.RemoveColor({
								color: '#000000',
								distance: 0.1
							}));
							img.applyFilters();
							if (this.canvas) {
								if (this.isLandscape()) {
									img.scaleToWidth(this.canvasWidth)
								} else {
									img.scaleToHeight(this.canvasHeight)
								}
								this.canvas.add(img);
								this.canvas.renderAll();
							}
						})
					}
				}
			});

			this.setupBrushes();

			this.canvas.on('object:added', this.onObjectAdded);

			if (!this.hasResizeListener) {
				window.addEventListener('resize', this.handleResize);
				this.hasResizeListener = true;
			}
		},
		setupBrushes () {
			if (this.canvas) {
				this.pencilBrush = new PencilBrush(this.canvas);
				this.pencilBrush.width = this.brushSize;
				this.pencilBrush.color = 'rgba(255, 255, 255, 1)';

				this.eraserBrush = new EraserBrush(this.canvas);
				this.eraserBrush.width = this.brushSize;

				this.canvas.freeDrawingBrush = this.pencilBrush;
			}
		},
		onClose () {
			this.destroyCanvas();
			this.updateRunnitState({
				inpaintingEditorOpen: false,
			});
		},
		async handleDone () {
			try {
				await this.updateRunnitState({
					inpaintingEditorSaving: true,
				});

				if (this.canvas) {
					const scaleFactor = this.imageWidth / this.canvasWidth;

					if (this.canvas.getObjects().filter((obj) => obj !== this.backgroundImage).length < 1) {
						// Empty mask, no need to save it
						this.$emit('input', null);
						this.onClose();
						return;
					}

					const img = this.canvas.item(0);

					try {
						img.set({ opacity: 0 });
						this.backgroundImage.set({ opacity: 0 });
						this.canvas.backgroundColor = 'black';
						this.canvas.renderAll();

						const dataURL = this.canvas.toDataURL({
							format: 'jpg',
							multiplier: scaleFactor,
						});

						const maskFile = await this.srcToFile(dataURL, `${uuidv4()}.jpg`, 'jpg');

						const savedMask = await this.uploadMaskToFirebase(maskFile);

						this.$emit('input', savedMask);
					} finally {
						this.backgroundImage.set({ opacity: 1 });
						this.canvas.backgroundColor = null;
						this.canvas.renderAll();
					}

				}
				this.onClose();

			} catch (err) {
				console.error('Error saving inpainting mask', err);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error saving inpainting mask',
					show: true,
				});
			} finally {
				await this.updateRunnitState({
					inpaintingEditorSaving: false,
				})
			}
		},
		async srcToFile(src, fileName, mimeType) {
			return (fetch(src)
					.then((res) => { return res.arrayBuffer();})
					.then((buf) => { return new File([buf], fileName, { type: mimeType })})
				);
		},
		async uploadMaskToFirebase (file) {
			let mask: RunnitMask = null;

			// Calculate hash of the file data
			const fileData = await file.arrayBuffer();
			const hashBuffer = await crypto.subtle.digest('SHA-256', fileData);
			const hashArray = Array.from(new Uint8Array(hashBuffer));
			const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

			// Check if hash exists already
			const masksRef = db.collection('runnitMasks')
				.where('hashHex', '==', hashHex)
				.where('userId', '==', this.user.id)
				.where('deletedAt', '==', null)
				.where('useCase', '==', 'INPAINTING')
				.limit(1);
			(await masksRef.get()).forEach(async (doc: any) => {
				mask = {
					...doc.data(),
					get id () { return doc.id },
				} as RunnitMask;
			});

			if (!mask) {
				// mask file
				const { name, type, size } = file;
				const fileRef = storage.ref(`runnitMasks/users/${this.user.id}/masks/${name}`);

				await fileRef.put(file); // acts like an upsert

				// Record mask file in database
				const nowDate: Date = new Date();
				await db.collection('runnitMasks')
				const addedMaskRef = await db.collection('runnitMasks')
					.add({
						createdAt: nowDate,
						deletedAt: null,
						userId: this.user.id,
						teamId: null,
						type: UPLOAD_FILE_TYPE.IMG,
						name: name,
						contentType: type,
						size: size,
						hashHex,
                        useCase: RUNNIT_MASK_USE_CASE.INPAINTING,
					});
				mask = {
					...(await addedMaskRef.get()).data(),
					get id () { return addedMaskRef.id },
				} as RunnitMask;
			}

			return mask;
		},
		onSelection (selection: string) {
			this.selection = selection;
			if (this.canvas) {
				if (selection === this.SELECTION.ERASER) {
					this.canvas.freeDrawingBrush = this.eraserBrush;
				} else {
					this.canvas.freeDrawingBrush = this.pencilBrush;
				}
			}
		},
		isSelected (selection: string) {
			return this.selection === selection;
		},
		onStepBack () {
			if (this.history.length > 0 && this.canvas) {
				const lastAction = this.history.pop();
				if (lastAction) {
					this.canvas.remove(lastAction);
					this.canvas.renderAll();
				}
			}
		},
		onDeleteInpaintingMask () {
			if (this.canvas) {
				this.canvas.getObjects().forEach((obj) => {
					if (obj !== this.backgroundImage) {
						this.canvas.remove(obj);
					}
				});
				this.history = [];
				this.canvas.renderAll();
			}
		},
		handleResize () {
			if (this.canvas) {
				this.canvas.setDimensions({
					width: this.canvasWidth,
					height: this.canvasHeight,
				});
				this.canvas.renderAll();
			}
		},
		onObjectAdded (e: { target: FabricObject }) {
			if (e.target !== this.backgroundImage) {
				this.history.push(e.target);
			}
		},
		destroyCanvas () {
			if (this.canvas) {
				this.canvas.off('object:added', this.onObjectAdded);
				this.canvas.dispose();
			}
		},
		actionsCardDragStart (event) {
			const style = window.getComputedStyle(event.target, null);
			event.dataTransfer.setData("text/plain",
				(parseInt(style.getPropertyValue("left"),10) - event.clientX) + ',' + (parseInt(style.getPropertyValue("top"),10) - event.clientY)
			);
		},
		actionsCardDragEnd (event) {
			const offset = event.dataTransfer.getData("text/plain").split(',');
			const dm = document.getElementById('actionsCardDraggable');
			dm.style.left = (event.clientX + parseInt(offset[0],10)) + 'px';
			dm.style.top = (event.clientY + parseInt(offset[1],10)) + 'px';
			event.preventDefault();
			return false;
		},
		dragOver (event) {
			event.preventDefault;
			return false;
		},
		aspectRatio () {
			if (this.imageWidth && this.imageHeight) {
				return this.imageWidth / this.imageHeight;
			}
			return 1;
		},
		isLandscape () {
			return this.aspectRatio() >= 1;
		},
		setCanvasHeight (): number {
			const height = this.$vuetify.breakpoint.smAndDown ? this.MOBILE_STATIC_HEIGHT : this.STATIC_HEIGHT
			this.canvasHeight = this.isExpandedMode ? this.imageHeight : this.isLandscape() ? height / this.aspectRatio() : height;
			return this.canvasHeight;
		},
		setCanvasWidth (): number {
			const width = this.$vuetify.breakpoint.smAndDown ? this.MOBILE_STATIC_WIDTH : this.STATIC_WIDTH
			this.canvasWidth = this.isExpandedMode ? this.imageWidth : this.isLandscape() ? width : width * this.aspectRatio();
			return this.canvasWidth;
		},
		imageLoaded (event) {
			this.imageWidth = this.$refs.imageRef.naturalWidth;
			this.imageHeight = this.$refs.imageRef.naturalHeight;
			this.initCanvas();
		}
	},
	beforeDestroy () {
		this.hasResizeListener = false;
		window.removeEventListener('resize', this.handleResize);
		this.destroyCanvas();
	},
	components: {
		BaseSlider,
		GlassButton,
		RunnitMenuOrBottomSheet,
		ComplexBackgroundSettingMenu2,
	},
});
