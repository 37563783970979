
import Vue from 'vue';
import MenuOrBottomSheet from '@/components/base/MenuOrBottomSheet.vue';

export default Vue.extend({
	name: 'SubscriptionLimitSelect',
	components: {
		MenuOrBottomSheet,
	},
	props: {
		limits: {
			type: Array,
			required: true,
		},
	},
	methods: {
		selectLimit(limit) {
			this.$emit('limit-selected', limit);
		},
	},
});
