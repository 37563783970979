
import Vue from 'vue';
import { mapActions } from 'vuex';
import { ROUTER } from '@/router/constants';

export default Vue.extend({
	name: 'Runnits',
	props: {},
	data () {
		return {};
	},
	created () {
		this.updateToolbar({
			insideRunnits: true,
		});
	},
	computed: {
		shouldRemoveSideMargins (): boolean {
			return [
				ROUTER.RUNNITS_BOARD,
			].includes(this.$route.name);
		},
		shouldNotConstrainWidth (): boolean {
			return [
				ROUTER.RUNNITS_BOARD,
			].includes(this.$route.name);
		},
	},
	methods: {
		...mapActions([
			'updateToolbar',
		]),
	},
});
