
import Vue from 'vue';
import RunnitDynamicFieldLabelRow from '@/views/Runnits/RunnitSettings/RunnitDynamicFieldLabelRow.vue';
import { RunnitDynamicFieldMixin } from '@/views/Runnits/RunnitSettings/mixins/RunnitDynamicFieldMixin';
import BaseSlider from '@/components/base/BaseSlider.vue';
import { RunnitNodeField } from '@run-diffusion/shared';
import _mapKeys from 'lodash/mapKeys';

export default Vue.extend({
	name: 'WidthHeightField',
	mixins: [
		RunnitDynamicFieldMixin,
	],
	props: {
		value: { type: [String, Object], default: null },
	},
	data () {
		return {
			MIN_DIMENSION: 8,
			DIVISIBLE_BY: 8,
			optionsMap: {},
		};
	},
	computed: {
		isSingularValueFormat () {
			return this._get(this.field, 'display.select.format') === 'value';
		},
		computedSelectFieldValue () {
			if (this.isSingularValueFormat) {
				return this.value || null;
			}
			if (!this.value || !this.value.width || !this.value.height) {
				return null;
			}
			return `${this.value.width}x${this.value.height}`;
		},
		sliderMinLimits () {
			const width: number = this._get(this.field, 'display.sliders.min[0]') || this.MIN_DIMENSION;
			const height: number = this._get(this.field, 'display.sliders.min[1]') || this.MIN_DIMENSION;
			return { width, height };
		},
		sliderMaxLimits () {
			const width: number = this._get(this.field, 'display.sliders.max[0]') || this.MIN_DIMENSION;
			const height: number = this._get(this.field, 'display.sliders.max[1]') || this.MIN_DIMENSION;
			return { width, height };
		},
		computedWidthAndHeight () {
			if (this.isSingularValueFormat) {
				const { width, height } = (this.value && this.optionsMap[this.value]) || {
					width: this.sliderMinLimits.width,
					height: this.sliderMinLimits.height,
				};
				return { width, height };
			}
			if (this.value && (this.value.width || this.value.height)) {
				const width: number = this.value.width || this.sliderMinLimits.width;
				const height: number = this.value.height || this.sliderMinLimits.height;
				return { width, height };
			}
			return {
				width: this.sliderMinLimits.width,
				height: this.sliderMinLimits.height,
			};
		},
	},
	watch: {
		field: {
			immediate: true,
			handler (newVal: RunnitNodeField, oldVal: RunnitNodeField) {
				if (newVal !== oldVal) {
					this.optionsMap = _mapKeys(this._get(this.field, 'display.select.options') || [], 'value');
				}
			},
		},
	},
	methods: {
		onSelectFieldInput ({ width, height, value }) {
			if (this.isSingularValueFormat) {
				this.$emit('input', value);
			} else {
				this.emitWidthHeightSeperatelyInput(width, height);
			}
		},
		onSliderWidthChange (width: number) {
			this.emitWidthHeightSeperatelyInput(width, this.computedWidthAndHeight.height);
		},
		onSliderHeightChange (height: number) {
			this.emitWidthHeightSeperatelyInput(this.computedWidthAndHeight.width, height);
		},
		emitWidthHeightSeperatelyInput (width: number, height: number) {
			this.$emit(
				'input',
				{
					width: width > 0 ? width : this.sliderMinLimits.width,
					height: height > 0 ? height : this.sliderMinLimits.height,
				},
			);
		},
	},
	components: {
		BaseSlider,
		RunnitDynamicFieldLabelRow,
	},
});
