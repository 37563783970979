
import Vue from 'vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import { functions } from '@/firebase';
import { SNACKBAR_STATUS } from '@/constants/constants';
import _isNil from 'lodash/isNil';
import _keys from 'lodash/keys';
import _get from 'lodash/get';
import {
	RUNNIT_NODE_DEF_PRICING_TYPE,
	RUNNIT_NODE_STATIC_FIELDS_KEY,
	RUNNIT_NODE_STATIC_FIELDS_SOURCE,
} from '@/constants/enums';
import { MyBalanceMixin } from '@/mixins';
import {
	RunnitNode,
	getRunnitNodeRunPrice,
	RunnitNodeStaticFieldsKey,
} from '@run-diffusion/shared';
import TokensSVG from '@/assets/TokensSVG.vue';
import BaseDrawer from '@/components/base/BaseDrawer.vue';
import RunnitTokens from '@/views/Runnits/RunnitTokens.vue';
import ComplexBackground from '@/components/designElements/ComplexBackground.vue';
import NumberWithIncrementer from '@/views/Runnits/RunnitSettings/NumberWithIncrementer.vue';
import AutoSaveRunnitNodeRunContainer from '@/views/Runnits/RunnitSettings/AutoSaveRunnitNodeRunContainer.vue';
import RunnitNodeFieldSettingsInternalEditor from '@/views/Runnits/RunnitSettings/internalAdminOnly/RunnitNodeFieldSettingsInternalEditor.vue';
import { RunnitBulkActionsMixin } from '@/mixins/RunnitBulkActionsMixin';
import { StoreLoadingState } from '@/store';

export default Vue.extend({
	name: 'RunnitSettingsDrawer',
	mixins: [
		MyBalanceMixin,
		RunnitBulkActionsMixin,
	],
	props: {
		isAnyNodeSelected: { type: Boolean, default: false },
		selectedNode: { type: Object, default: null },
	},
	data () {
		return {
			RUNNIT_NODE_STATIC_FIELDS_KEY,

			incrementAutoSaveTrigger: 0,
			inputValues: {},
			staticInputValues: {},

			bottomOfScrollReached: false,

			// FORM
			formValid: true,

			fieldSettingsInternalEditorDialog: {
				open: false,
				selectedNode: null,
			},
		};
	},
	computed: {
		...mapGetters([
			'isMobileNavOpen',
		]),
		...mapState([
			'user',
			'team',
			'loadingDraftRunnitNodeRun',
			'draftRunnitNodeRun',
			'runnitState',
		]),
		runnitButtonDisabled () {
			return !!(
				_isNil(this.totalRunCost) ||
				this.totalRunCost > this.chooseBalanceOptionDisplay.value ||
				this.runnitState.isQueuingDraftRunnitNodeRun ||
				this.disableActions ||
				(!this.user.isAdmin && !_get(this.selectedNode, 'nodeDef.publishedAt'))
			);
		},
		disableActions () {
			return !(!this.loadingDraftRunnitNodeRun && this.draftRunnitNodeRun);
		},
		determinedStaticFields () {
			if (!this.selectedNode) return {};
			return (
				this._get(this.selectedNode, 'staticFieldsSource') === RUNNIT_NODE_STATIC_FIELDS_SOURCE.NODE
					? this._get(this.selectedNode, 'staticFields')
					: this._get(this.selectedNode, 'nodeDef.staticFields')
			) || {};
		},
		numResultsStaticInputValue () {
			if (
				this.determinedStaticFields[RUNNIT_NODE_STATIC_FIELDS_KEY.numResults] &&
				this.staticInputValues[RUNNIT_NODE_STATIC_FIELDS_KEY.numResults] > 0
			) {
				return this.staticInputValues[RUNNIT_NODE_STATIC_FIELDS_KEY.numResults];
			}
			return 1;
		},
		totalRunCost () {
			if (
				this.selectedNode &&
				typeof this.selectedNode.nodeDef === 'object' && // Nested object is fetched)
				this.selectedNode.nodeDef.pricingType === RUNNIT_NODE_DEF_PRICING_TYPE.COST &&
				this.selectedNode.nodeDef.costPerResult > 0
			) {
				return getRunnitNodeRunPrice(this.selectedNode.nodeDef.costPerResult, this.numResultsStaticInputValue);
			}
			return null; // null means can't get price yet, should disable the Runnit run button
		},
		chooseBalanceOptionDisplay () {
			if (!this.runnitState.runnit) return null;
			if (this.runnitState.runnit && this.runnitState.runnit.teamId) {
				if (!this.team) return null;
				return {
					isUser: false,
					isTeam: true,
					label: this.team.name,
				};
			} else {
				return {
					isUser: true,
					isTeam: false,
					label: 'Personal Balance',
				};
			}
		},
	},
	watch: {
		selectedNode: {
			immediate: true,
			handler (newVal: RunnitNode, oldVal: RunnitNode) {
				const newId: string = this._get(newVal, 'id') || null;
				const oldId: string = this._get(oldVal, 'id') || null;
				if (newId && newId !== oldId) {
					this.bindDraftRunnitNodeRun({ userId: this.user.id, nodeId: newId });
				}
			},
		},
		loadingDraftRunnitNodeRun: {
			immediate: true,
			handler (newVal: StoreLoadingState, oldVal: StoreLoadingState) {
				if (oldVal && !newVal && !this.draftRunnitNodeRun) {
					// create the initial draft with the default values
					this.incrementAutoSaveTrigger++;
				}
			}
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
			'bindDraftRunnitNodeRun',
			'updateRunnitState',
		]),
		_isNil,
		// TODO: if the selected team isn't active, should we disable the Runnit button?
		setSettingsDrawerOpen (value: boolean) {
			this.$emit('set-settings-drawer-open', value);
			if (value) {
				this.setBulkActionMenuOpen(false);
			}
		},
		onStaticInputValuesChange (key: RunnitNodeStaticFieldsKey, val: any) {
			this.staticInputValues = {
				...this.staticInputValues,
				[key]: val,
			};

			this.$nextTick(() => {
				// on next tick, so that the state set above has a chance to render before triggering auto save
				this.incrementAutoSaveTrigger++;
			});
		},
		async onGenerateClick () {
			if (this.runnitState.isQueuingDraftRunnitNodeRun) return;
			if (
				!this.$refs.form.validate() ||
				!this.draftRunnitNodeRun // This means they haven't touched the form yet, or something...
			) {
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Form invalid, check fields',
					show: true,
				});
				return;
			}

			const onError: Function = (e) => {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error executing this runnit, please reach out to report issues by clicking the support button in our top toolbar',
					show: true,
				});
			};
			try {
				const staticInputValues: Record<RunnitNodeStaticFieldsKey, any> = {};
				_keys(this.determinedStaticFields).forEach((key: RunnitNodeStaticFieldsKey) => {
					// Only send values that are absolutely defined
					staticInputValues[key] = this.staticInputValues[key];
				});

				this.runnitState.isQueuingDraftRunnitNodeRun = true;
				const functionRef = functions
					.httpsCallable('runRunnitNode');
				const { success } = (await functionRef({
					nodeRunId: this.draftRunnitNodeRun.id,
					inputValues: this.inputValues,
					staticInputValues,
				})).data;
				if (!success) {
					onError(new Error('runRunnitNode returned success: false'));
				} else if (this.$vuetify.breakpoint.xsOnly) {
					this.setSettingsDrawerOpen(false);
				}
			} catch (e) {
				onError(e);
			} finally {
				this.runnitState.isQueuingDraftRunnitNodeRun = false;
			}
		},
		toggleAdminFieldsEditor (selectedNode: any) {
			this.fieldSettingsInternalEditorDialog.open = !this.fieldSettingsInternalEditorDialog.open;
			this.fieldSettingsInternalEditorDialog.selectedNode = selectedNode;
		},
		handleScrollReachingBottom () {
			const scrollContainer = this.$refs.scrollContainer;

			this.bottomOfScrollReached = scrollContainer.scrollTop > 240;
		},
	},
	components: {
		TokensSVG,
		BaseDrawer,
		RunnitTokens,
		ComplexBackground,
		NumberWithIncrementer,
		AutoSaveRunnitNodeRunContainer,
		RunnitNodeFieldSettingsInternalEditor,
	},
});
