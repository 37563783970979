import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"runnit-single-select"},[_c('runnit-dynamic-field-label-row',{attrs:{"field":_vm.field}}),(_vm.useImageSelect)?_c('div',{staticClass:"runnit-images-select"},[(_vm.useImageDialog)?_c('runnit-menu-or-bottom-sheet',{attrs:{"title":_vm.field.label,"value":_vm.runnitState.imageSelectOpen,"max-width":470,"min-width":470,"max-height":700,"nudge-left":510,"bottom-sheet-on-small":""},on:{"on-close":_vm.closeImageSelect},scopedSlots:_vm._u([{key:"trigger",fn:function({ on }){return [(_vm.localValue)?_c('div',_vm._g({staticClass:"single-select-image-options-activator",on:{"click":_vm.openImageSelect}},on),[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"single-select-image-options-activator-image"},[_c('img',{attrs:{"alt":_vm.localValue.text,"src":_vm._get(_vm.localValue, 'imageUrl')}})]),_c('div',{staticClass:"single-select-image-options-activator-label"},[_vm._v(" "+_vm._s(_vm.localValue.text)+" ")])]),_c('div',{staticClass:"single-select-image-options-activator-icon"},[_c(VIcon,{staticStyle:{"color":"var(--grey-550)","font-size":"26px"}},[_vm._v("mdi-chevron-down-circle")])],1)]):_vm._e()]}},{key:"content",fn:function({ close }){return [_c('div',{staticClass:"runnit-images-select-options-container",style:({
						'grid-template-columns': `repeat(auto-fit, minmax(${_vm.imageMinWidth ? `${_vm.imageMinWidth}px` : '140px'}, 1fr))`,
					})},_vm._l((_vm.options),function(option){return _c('div',{key:option.value,staticClass:"clickable",staticStyle:{"display":"grid"},on:{"click":function($event){return _vm.onSelectImageOption(option, close)}}},[(option.text)?_c('span',[_vm._v(_vm._s(option.text))]):_vm._e(),_c('img',{staticClass:"runnit-images-select-image shadow-3-2",class:{
								'selected': _vm._get(_vm.localValue, 'value') === option.value,
							},attrs:{"alt":option.text,"src":_vm._get(option, 'imageUrl')}})])}),0)]}}],null,false,815865913)}):_c('menu-or-bottom-sheet',{attrs:{"max-width":600,"nudge-bottom":50},scopedSlots:_vm._u([{key:"trigger",fn:function({ on }){return [(_vm.localValue)?_c('div',_vm._g({staticClass:"single-select-image-options-activator"},on),[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"single-select-image-options-activator-image"},[_c('img',{attrs:{"alt":_vm.localValue.text,"src":_vm._get(_vm.localValue, 'imageUrl')}})]),_c('div',{staticClass:"single-select-image-options-activator-label"},[_vm._v(" "+_vm._s(_vm.localValue.text)+" ")])]),_c('div',{staticClass:"single-select-image-options-activator-icon"},[_c(VIcon,{staticStyle:{"color":"var(--grey-550)","font-size":"26px"}},[_vm._v("mdi-chevron-down-circle")])],1)]):_vm._e()]}},{key:"content",fn:function({ close }){return [(_vm.options.length)?_c('div',{staticClass:"single-select-image-option-container",style:({
						width: _vm.$vuetify.breakpoint.smAndUp ? '375px' : '100%',
						padding: _vm.$vuetify.breakpoint.smAndUp ? '8px' : '15px 8px 20px 8px',
						borderTopLeftRadius: _vm.$vuetify.breakpoint.smAndUp ? '0' : 'var(--global-border-radius)',
						borderTopRightRadius: _vm.$vuetify.breakpoint.smAndUp ? '0' : 'var(--global-border-radius)',
					})},[_c('complex-background-base-styled-menu'),_vm._l((_vm.options),function(option,index){return _c('div',{key:option.value,staticClass:"single-select-image-option",style:({
							marginBottom: index < _vm.options.length - 1 ? '7px' : '0',
						}),on:{"click":function($event){return _vm.onSelectImageOption(option, close)}}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"single-select-image-option-image"},[_c('img',{attrs:{"alt":option.text,"src":_vm._get(option, 'imageUrl')}})]),_c('div',{staticClass:"ml-4"},[_vm._v(" "+_vm._s(option.text)+" ")])]),_c('div',[_c(VIcon,{staticClass:"mr-3",staticStyle:{"color":"var(--primary-blue)","font-size":"23px"},style:({
									color: _vm._get(_vm.localValue, 'value') === option.value ? 'var(--primary-blue)' : 'var(--grey-600)',
								})},[_vm._v(" "+_vm._s(_vm._get(_vm.localValue, 'value') === option.value ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank')+" ")])],1)])})],2):_vm._e()]}}],null,false,1180186904)})],1):(_vm.useRadioSelect)?_c('div',{staticClass:"runnit-radio-group"},[_c(VRadioGroup,{staticStyle:{"margin":"0"},attrs:{"value":_vm._get(_vm.localValue, 'value'),"mandatory":_vm.field.required,"hide-details":"","rules":_vm.field.required ? _vm.rules.requiredRadioGroup : _vm.rules.optionalRadioGroup},on:{"change":_vm.emitInput}},_vm._l((_vm.options),function(option,index){return _c('div',{key:option.value,staticClass:"runnit-radio-item",style:({
					margin: index === _vm.options.length - 1 ? '0' : '0 0 22px 0',
				})},[_c('div',{staticClass:"runnit-radio-label"},[_vm._v(" "+_vm._s(option.text)+" ")]),_c(VRadio,{attrs:{"value":option.value,"label":""}})],1)}),0)],1):[((_vm.options || []).length < 8)?_c(VSelect,{class:{
				required: !!_vm.field.required,
			},attrs:{"items":_vm.options || [],"required":!!_vm.field.required,"placeholder":_vm.field.placeholder,"item-text":"text","item-value":"value","dense":"","filled":"","flat":"","solo-inverted":"","hide-details":"auto","rules":_vm.field.required ? _vm.rules.requiredSelectField : _vm.rules.optionalSelectField,"value":_vm.value},on:{"input":_vm.emitInput}}):_c(VAutocomplete,{class:{
				required: !!_vm.field.required,
			},attrs:{"items":_vm.options || [],"required":!!_vm.field.required,"placeholder":_vm.field.placeholder,"item-text":"text","item-value":"value","dense":"","filled":"","flat":"","solo-inverted":"","hide-details":"auto","rules":_vm.field.required ? _vm.rules.requiredSelectField : _vm.rules.optionalSelectField,"value":_vm.value},on:{"input":_vm.emitInput}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }