import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isStillLoadingInitialPage)?_c('div',{staticClass:"d-flex justify-center align-center full-width full-height"},[_c('LoadingSVG',{staticClass:"my-10",attrs:{"height":200,"width":200}})],1):((
		_vm.forceCreateNewTeam ||
		!_vm.team ||
		(_vm.team && _vm.team.isOnboarding)
	))?_c('team-plan-signup',{on:{"on-back":_vm.onTeamPlanSignupBack}}):_c(VContainer,{staticClass:"pt-10 pb-12"},[_c(VRow,[_c(VCol,{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-space-between align-center mt-6"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex align-center"},[(_vm.$route.query.finishedConnectingTeam)?_c('base-button',{on:{"click":_vm.goHome}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-arrow-left-thin")]),_vm._v(" Home Page ")],1):_c('back-button',{on:{"click":_vm.goHome}}),_c('div',{staticClass:"page-title ml-6"},[_vm._v(" Team ")]),_c(VBtn,{staticClass:"ml-4",staticStyle:{"position":"relative","top":"5px"},attrs:{"text":"","color":"primary","href":"https://learn.rundiffusion.com/team-plan","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.mdAndUp ? 'Learn how team plans work' : 'Learn')+" "),_c(VIcon,{staticStyle:{"color":"var(--primary-blue)","margin":"0 0 0 5px"},attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)],1)]),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex align-center full-width",class:{
								'justify-end': _vm.$vuetify.breakpoint.smAndUp,
								'justify-center': _vm.$vuetify.breakpoint.xsOnly,
							}},[_c('div',{staticClass:"d-flex gradient-text",staticStyle:{"font-size":"1.5rem"},style:({
									fontSize: _vm.$vuetify.breakpoint.smAndDown ? '1.2rem' : '1.5rem',
								})},[_vm._v(" "+_vm._s(_vm.team.name)+" ")]),(_vm.user && (_vm.user.isAdmin || _vm.isAdminProxyingUser))?_c('admin-team-stats',{staticClass:"ml-2",attrs:{"team":_vm.team}}):_vm._e()],1),_c('div',{staticClass:"d-flex align-center full-width",class:{
								'justify-end': _vm.$vuetify.breakpoint.smAndUp,
								'justify-center': _vm.$vuetify.breakpoint.xsOnly,
							}},[((
									_vm.user.isAdmin ||
									_vm._get(_vm.team, 'features.reporting')
								))?_c(VBtn,{staticClass:"mr-1",attrs:{"small":"","text":"","color":(_vm.user.isAdmin && !_vm._get(_vm.team, 'features.reporting')) ? 'deep-orange' : 'primary'},on:{"click":_vm.goToTeamReporting}},[_c(VIcon,{style:({
										color: (_vm.user.isAdmin && !_vm._get(_vm.team, 'features.reporting')) ? 'var(--deep-orange)' : 'var(--primary-blue)',
									}),attrs:{"small":"","left":""}},[_vm._v("mdi-chart-box-outline")]),_vm._v(" Reporting ")],1):_vm._e(),(_vm.isAdmin)?_c(VBtn,{staticClass:"mr-1",attrs:{"small":"","text":"","color":"primary"},on:{"click":_vm.handleEditTeamName}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("mdi-pencil-outline")]),_vm._v(" Edit ")],1):_vm._e(),_c('team-picker',{attrs:{"allow-new-team-creation":""},on:{"on-new-team-btn-select":_vm.onNewTeamBtnSelect},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":"","text":"","color":"primary"}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("mdi-account-multiple-outline")]),_vm._v(" Change ")],1)]}}])})],1)])],1)],1),_c('div',[_c('page-top-navigation',{staticClass:"ml-4",attrs:{"tabs-data":_vm.tabsData}})],1)]),_c('edit-team-dialog',{attrs:{"edit-mode":""},model:{value:(_vm.editTeamDialogOpen),callback:function ($$v) {_vm.editTeamDialogOpen=$$v},expression:"editTeamDialogOpen"}})],1),_c(VRow,{attrs:{"justify":"center"}},[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }