
import Vue from 'vue';
import { db } from '@/firebase';
import { Runnit, RunnitNode } from '@run-diffusion/shared';
import _isNil from 'lodash/isNil';
import { mapActions, mapState } from 'vuex';
import { SNACKBAR_STATUS } from '@/constants/constants';
import {
	RUNNIT_TOOL_SETTINGS_VALUES,
	RUNNIT_TOOL_SETTINGS_MENU_ITEMS,
} from '@/views/Runnits/constants';
import GlassButton from '@/components/base/GlassButton.vue';
import EmptyState from '@/components/states/EmptyState.vue';
import LoadingState from '@/components/states/LoadingState.vue';
import BaseStyledMenu from '@/components/base/BaseStyledMenu.vue';
import ImageGallery from '@/components/ImageGallery/ImageGallery.vue';
import RunnitSettingsDrawer from '@/views/Runnits/RunnitSettingsDrawer.vue';
import ComplexBackground from '@/components/designElements/ComplexBackground.vue';
import ImageGalleryDialog from '@/components/ImageGallery/ImageGalleryDialog.vue';
import RunnitToolsLibraryDialog from '@/views/Runnits/RunnitToolsLibraryDialog.vue';
import RunnitNodeSettingsDialog from '@/views/Runnits/RunnitNodeSettingsDialog.vue';
import DoubleConfirmDangerDialog from '@/components/base/DoubleConfirmDangerDialog.vue';
import { get$bindFirestoreOptions } from '@/mixins';
import { RunnitsImageSelectMixin } from '@/mixins/RunnitsImageSelectMixin';

export default Vue.extend({
	name: 'RunnitBoard',
	data () {
		return {
			collapsedNodesMap: {},

			addingNewToolLoading: false,

			nodeSettingsDialog: {
				open: false,
				selectedNode: null,
			},

			loadingLocalRunnit: false,
			localRunnit: null,

			runnitToolSettingsMenuItems: RUNNIT_TOOL_SETTINGS_MENU_ITEMS,
			deleteToolDialog: {
				open: false,
				deleting: false,
				selectedNode: null,
			},

			runnitNodesLoading: true,
		};
	},
	mixins: [
		RunnitsImageSelectMixin,
	],
	computed: {
		...mapState([
			'user',
			'runnitState',
			'runnitNodes',
			'runnitNodesMap',
		]),
		computedDescriptionTruncateLength () {
			return this.$vuetify.breakpoint.xsOnly ? 30 : 75;
		},
	},
	watch: {
		'$route.params.runnitId': {
			immediate: true,
			handler (newVal: string, oldVal: string) {
				if (newVal !== oldVal) {
					this.localBindRunnit(newVal);
				}
			},
		},
		localRunnit: {
			immediate: true,
			handler (newVal: Runnit, oldVal: Runnit) {
				if (newVal !== oldVal) {
					const newRunnitId: string = this._get(newVal, 'id') || null;
					const oldRunnitId: string = this._get(this.runnitState.runnit, 'id') || null;
					this.updateRunnitState({
						runnit: newVal,
						...(newRunnitId !== oldRunnitId && {
							selectedNodeId: null,
						}),
					});

					if (newVal) {
						this.bindRunnitNodes({ runnitId: newVal.id });
					}
				}
			},
		},
		runnitNodes: {
			immediate: true,
			handler (newVal: RunnitNode[], oldVal: RunnitNode[]) {
				if (newVal !== oldVal) {
					this.initCollapsedNodesMap(newVal);
				}
				if (
					newVal &&
					newVal.length &&
					this.$vuetify.breakpoint.mdAndUp
				) {
					this.onSelectNode(newVal[0].id);
				} else {
					this.onSelectNode(null);
				}

				setTimeout(() => {
					this.runnitNodesLoading = false;
				}, 500);
			},
		},
	},
	methods: {
		...mapActions([
			'updateSnackbar',
			'bindRunnitNodes',
			'updateRunnitState',
		]),
		async localBindRunnit (runnitId: string) {
			try {
				this.loadingLocalRunnit = true;
				await this.$bind(
					'localRunnit',
					db.doc(`runnits/${runnitId}`),
					get$bindFirestoreOptions(),
				);
			} catch (e) {
				console.error(e);
				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: 'Error loading Runnit',
					show: true,
				});
			} finally {
				this.loadingLocalRunnit = false;
			}
		},
		isNodeCollapsed (nodeId: string) {
			return !!(this.collapsedNodesMap[nodeId]);
		},
		initCollapsedNodesMap (runnitNodes: RunnitNode[]) {
			this.collapsedNodesMap = {};
			(runnitNodes || []).forEach((node: RunnitNode) => {
				this.collapsedNodesMap[node.id] = false;
			});
		},
		onSelectNode (nodeId: string) {
			this.updateRunnitState({
				selectedNodeId: nodeId,
				settingsDrawerOpen: !!nodeId,
			});
		},
		setSettingsDrawerOpen (isOpen: boolean) {
			this.updateRunnitState({
				settingsDrawerOpen: isOpen,
				...(!isOpen && {
					imageGalleryDialogOpen: false,
					promptGeneratorOpen: false,
					imageSelectOpen: false,
					inpaintingEditorOpen: false,
				}),
				...(this.runnitState.selectedNodeId && !isOpen && {
					selectedNodeId: null,
				}),
			});
		},
		toggleNodeCollapse (event: any, nodeId: string) {
			event.stopPropagation();
			this.collapsedNodesMap[nodeId] = !this.collapsedNodesMap[nodeId];
			this.$forceUpdate();
		},
		addAnotherNode () {
			this.updateRunnitState({
				toolsLibraryDialogOpen: true,
			});
		},
		onToolsLibraryDialogClose (open: boolean) {
			this.updateRunnitState({
				toolsLibraryDialogOpen: open,
			});
		},
		async onSelectedSettingMenuItem (selectedMenuItem: string, selectedNode: RunnitNode) {
			if (selectedMenuItem === RUNNIT_TOOL_SETTINGS_VALUES.DELETE) {
				this.deleteToolDialog.open = true;
				this.deleteToolDialog.selectedNode = selectedNode;
			} else if (selectedMenuItem === RUNNIT_TOOL_SETTINGS_VALUES.EDIT_INFO) {
				this.nodeSettingsDialog.open = true;
				this.nodeSettingsDialog.selectedNode = selectedNode;
			} else if ([RUNNIT_TOOL_SETTINGS_VALUES.MOVE_UP, RUNNIT_TOOL_SETTINGS_VALUES.MOVE_DOWN].includes(selectedMenuItem)) {
				await this.moveNode(selectedMenuItem, selectedNode);
			}
		},
		onRunnitNodeSettingsClose () {
			this.nodeSettingsDialog.open = false;
			this.nodeSettingsDialog.selectedNode = null;
		},
		onDeleteCancelReset () {
			this.deleteToolDialog.open = false;
			this.deleteToolDialog.deleting = false;
			this.deleteToolDialog.selectedNode = null;
		},
		async handleDeleteNodeDefConfirm (closeAndResetCallback: Function) {
			try {
				this.deleteToolDialog.deleting = true;
				const { id, runnitId } = this.deleteToolDialog.selectedNode;
				const runnitNodeRef = db.doc(`runnits/${runnitId}/runnitNodes/${id}`);

				await runnitNodeRef.update({
					deletedAt: new Date(),
				});
				this.updateSnackbar({
					status: SNACKBAR_STATUS.SUCCESS,
					message: `Success! Deleted the ${this.deleteToolDialog.selectedNode.title} tool from the Runnit`,
					show: true,
				});
			} catch (e) {
				console.error(e);

				this.updateSnackbar({
					status: SNACKBAR_STATUS.ERROR,
					message: `Error! Problem deleting this tool from the Runnit, please reach out to report issues by clicking the support button in our top toolbar`,
					show: true,
				});
			} finally {
				if (closeAndResetCallback) {
					closeAndResetCallback();
				}
				this.onDeleteCancelReset();
			}
		},
		moveNode (selectedMenuItem: string, node: RunnitNode) {
			let nodeIndex: number = null;
			const nodeIdsList: string[] = (this.runnitNodes || []).map(({ id }, index: number) => {
				if (id === node.id) nodeIndex = index;
				return id;
			});

			// Can't move up the first node or move down the last node
			if (
				_isNil(nodeIndex) ||
				(
					selectedMenuItem === RUNNIT_TOOL_SETTINGS_VALUES.MOVE_UP &&
					nodeIndex <= 0
				) ||
				(
					selectedMenuItem === RUNNIT_TOOL_SETTINGS_VALUES.MOVE_DOWN &&
					nodeIndex >= this.runnitNodes.length - 1
				)
			) {
				return;
			}

			// Swap the node with the one before it or after it
			if (selectedMenuItem === RUNNIT_TOOL_SETTINGS_VALUES.MOVE_UP) {
				[nodeIdsList[nodeIndex - 1], nodeIdsList[nodeIndex]] = [nodeIdsList[nodeIndex], nodeIdsList[nodeIndex - 1]];
			} else if (selectedMenuItem === RUNNIT_TOOL_SETTINGS_VALUES.MOVE_DOWN) {
				[nodeIdsList[nodeIndex], nodeIdsList[nodeIndex + 1]] = [nodeIdsList[nodeIndex + 1], nodeIdsList[nodeIndex]];
			}

			// Set sortOrder
			return db.runTransaction(async (transaction) => {
				nodeIdsList.forEach((id: string, index: number) => {
					transaction.update(
						db.doc(`runnits/${node.runnitId}/runnitNodes/${id}`),
						{ sortOrder: index },
					);
				});
			});
		},
		determineMenuItems (nodeIndex: string | number) {
			const firstNodeIndexNum: number = 0;
			const lastNodeIndexNum: number = this.runnitNodes.length - 1;
			const onlyOneNodeExists: boolean = this.runnitNodes.length === 1;
			const shouldShowMoveUp: boolean = +nodeIndex !== firstNodeIndexNum;
			const shouldShowMoveDown: boolean = +nodeIndex !== lastNodeIndexNum;
			let menuItems = [...RUNNIT_TOOL_SETTINGS_MENU_ITEMS];

			if (onlyOneNodeExists) {
				return menuItems;
			}

			const MOVE_UP_MENU_ITEM = {
				id: 2,
				icon: 'mdi-chevron-up',
				label: 'Move Tool Up',
				description: '',
				value: RUNNIT_TOOL_SETTINGS_VALUES.MOVE_UP,
			};
			const MOVE_DOWN_MENU_ITEM = {
				id: 3,
				icon: 'mdi-chevron-down',
				label: 'Move Tool Down',
				description: '',
				value: RUNNIT_TOOL_SETTINGS_VALUES.MOVE_DOWN,
			};

			if (shouldShowMoveUp) {
				menuItems = menuItems.concat(MOVE_UP_MENU_ITEM);
			}

			if (shouldShowMoveDown) {
				menuItems = menuItems.concat(MOVE_DOWN_MENU_ITEM);
			}

			menuItems.sort((a, b) => a.id - b.id);

			return menuItems;
		}
	},
	components: {
		LoadingState,
		RunnitNodeSettingsDialog,
		DoubleConfirmDangerDialog,
		BaseStyledMenu,
		GlassButton,
		ImageGallery,
		RunnitToolsLibraryDialog,
		EmptyState,
		ComplexBackground,
		ImageGalleryDialog,
		RunnitSettingsDrawer,
	},
});
