import { VHover } from 'vuetify/lib/components/VHover';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',_vm._g(_vm._b({staticClass:"glass-button",style:({
			display: 'flex',
			width: 'fit-content',
			padding: _vm.computedPadding,
			fontWeight: 'bold',
			borderRadius: '16px',
			backgroundImage: _vm.selected
				? 'radial-gradient(circle at 5% 0, rgba(127, 86, 182, 20), rgba(255, 255, 255, 0.1) 106%)'
				: 'radial-gradient(circle at 5% 0, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0) 156%)',
			...(hover && !_vm.disabled && {
				cursor: 'pointer',
			}),
			..._vm.styles,
		})},'div',_vm.$attrs,false),_vm.disabled ? {} : _vm.$listeners),[(_vm.loading)?_c('div',{staticClass:"d-flex justify-center align-center"},[_c(VProgressCircular,{attrs:{"indeterminate":true,"size":"40","width":"2","color":"#4d4d4d"}})],1):_vm._t("default")],2)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }