
import Vue from 'vue';
import WindowStepper from '@/components/WindowStepper.vue';
import BaseToggleButton from '@/components/base/BaseToggleButton.vue';
import RunnitsSubscriptionCard from '@/views/Runnits/pages/RunnitsSubscriptionsPage/RunnitsSubscriptionCard.vue';

export default Vue.extend({
	name: 'RunnitsSubscriptionsPage',
	props: {},
	data () {
		return {
			// TODO replace this temp dummy data with actual data
			isMonthly: false,
			currentClub: 'RUNFREE',
			carouselModel: 2,
			clubs: [
				{
					key: 'RUNFREE',
					title: 'Free',
					monthlyPrice: 0,
					yearlyPrice: 0,
					benefitsIntro: 'Includes:',
					callToActionText: 'Get started',
					callToActionIcon: 'mdi-arrow-right-thin',
					addOns: [
						{
							id: 'free-token',
							type: 'Tokens',
							description: '10 / day',
						},
						{
							id: 'free-runnit',
							type: 'Runnits',
							description: '1',
						},
					],
					runnitsLimit: 1,
					toolsLimit: 3,
					tokensLimit: 150,
					limits: [
						{
							id: '1',
							label: 'Runnit',
							value: '3',
						},
						{
							id: '2',
							label: 'Runnit',
							value: '10',
						},
						{
							id: '3',
							label: 'Runnit',
							value: '20',
						},
					],
				},
				{
					key: 'RUNHOB',
					title: 'Hobby',
					monthlyPrice: 1199,
					yearlyPrice: 999,
					benefitsIntro: 'Everything in Free, Plus:',
					callToActionText: 'Get started',
					callToActionIcon: 'mdi-arrow-right-thin',
					addOns: [
						{
							id: 'hob-token',
							type: 'Tokens',
							description: '1000 / month',
						},
						{
							id: 'hob-runnit',
							type: 'Runnits',
							description: '5',
						},
					],
					runnitsLimit: 3,
					toolsLimit: 5,
					tokensLimit: 10000,
					limits: [
						{
							id: '1',
							label: 'Runnit',
							value: '3',
						},
						{
							id: '2',
							label: 'Runnit',
							value: '10',
						},
						{
							id: '3',
							label: 'Runnit',
							value: '20',
						},
					],
				},
				{
					key: 'RUNPRO',
					title: 'Pro',
					isBestPlan: true,
					monthlyPrice: 2999,
					yearlyPrice: 2399,
					benefitsIntro: 'Everything in Hobby, Plus:',
					callToActionText: 'Get started',
					callToActionIcon: 'mdi-arrow-right-thin',
					addOns: [
						{
							id: 'pro-token',
							type: 'Tokens',
							description: '5000 / month',
						},
						{
							id: 'pro-runnit',
							type: 'Runnits',
							description: '10',
						},
					],
					runnitsLimit: 10,
					toolsLimit: 10,
					tokensLimit: 18000,
					limits: [
						{
							id: '1',
							label: 'Runnit',
							value: '3',
						},
						{
							id: '2',
							label: 'Runnit',
							value: '10',
						},
						{
							id: '3',
							label: 'Runnit',
							value: '20',
						},
					],
				},
				// {
				// 	key: 'RUNTEAM',
				// 	title: 'Team',
				// 	monthlyPrice: 5999,
				// 	yearlyPrice: 4899,
				// 	benefitsIntro: 'Everything in Pro, Plus:',
				// 	callToActionText: 'Get started',
				// 	callToActionIcon: 'mdi-arrow-right-thin',
				// 	addOns: [
				// 		{
				// 			id: 'pro-token',
				// 			type: 'Tokens',
				// 			description: '5000 / month',
				// 		},
				// 		{
				// 			id: 'pro-runnit',
				// 			type: 'Runnits',
				// 			description: '10',
				// 		},
				// 	],
				// },
				{
					key: 'RUNENT',
					title: 'Enterprise',
					isEnterprise: true,
					monthlyPrice: 2999,
					yearlyPrice: 2399,
					benefitsIntro: 'Everything in Team, Plus:',
					callToActionText: 'Schedule call',
					callToActionIcon: 'mdi-calendar-check',
					addOns: [
						{
							id: 'pro-token',
							type: 'Tokens',
							description: '5000 / month',
						},
						{
							id: 'pro-runnit',
							type: 'Runnits',
							description: '10',
						},
					],
				},
			],
		};
	},
	methods: {
		// TODO it'll probably be better to adjust the real data to inject a dollar amount and a cents amount rather than calling these functions in the html.
		getDollars (amount) {
			return +`${amount}`.slice(0, -2);
		},
		getCents (amount) {
			return +`${amount}`.slice(-2);
		},
		switchPlan (club) {
			console.log('Implement switchPlan', {club});
			this.currentClub = club.key;
		},
		handleCtaClick (club) {
			if (club.key === 'RUNENT') {
				window.open('https://calendly.com/rundiffusion/30min', '_blank');
			}
		},
		getComputedPrice (club, isMonthly) {
			return isMonthly ? club.monthlyPrice : club.yearlyPrice;
		},
		handleStepClick (stepIndex) {
			this.carouselModel = stepIndex;
		},
		prevSlide () {
			if (this.carouselModel > 0) {
				this.carouselModel--;
			}
		},
		nextSlide () {
			if (this.carouselModel < this.clubs.length - 1) {
				this.carouselModel++;
			}
		},
	},
	components: {
		WindowStepper,
		BaseToggleButton,
		RunnitsSubscriptionCard,
	},
});
