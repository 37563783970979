import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"image-info-fields",style:({
            ...(!_vm.insideNode && {
                background: 'var(--grey-875)',
            }),
            ...(_vm.insideNode && {
                background: 'var(--grey-900)',
            }),
        })},_vm._l((_vm.fieldsThatShouldDisplay),function(field){return _c('div',{key:field.uuid,staticClass:"image-info-field",style:({
                ...([
                        _vm.RUNNIT_NODE_FIELD_TYPE.PROMPT,
                        _vm.RUNNIT_NODE_FIELD_TYPE.NEG_PROMPT,
                        ...(
                            (
                                field.type === _vm.RUNNIT_NODE_FIELD_TYPE.IMG &&
                                _vm.nodeRun.inputs[field.fieldDefUuid]
                            )
                            ? [_vm.RUNNIT_NODE_FIELD_TYPE.IMG]
                            : []
                        ),
                    ].includes(field.type) && {
                    width: _vm.determineWidth(),
                    padding: '0',
                }),
				...(field.__rgroup && {width: '100%'}),
            })},[(field.__rgroup)?[_c('div',{staticClass:"image-info-fields-group-label clickable",on:{"click":function($event){return _vm.toggleGroupExpansion(field.uuid)}}},[_vm._v(" "+_vm._s(field.label)+" "),_c(VIcon,{staticStyle:{"color":"var(--grey-500)"}},[_vm._v("mdi-chevron-"+_vm._s(_vm.expandedGroups[field.uuid] ? 'up' : 'down'))])],1),_c(VExpandTransition,[(_vm.expandedGroups[field.uuid])?_c('div',{staticStyle:{"margin":"0 -10px"}},[_c('image-info-fields',{attrs:{"fields":field.fields,"nodeRun":_vm.nodeRun,"insideDrawer":_vm.insideDrawer,"insideNode":_vm.insideNode,"insideCarousel":_vm.insideCarousel,"ignoreTruncation":_vm.ignoreTruncation,"index":_vm.index,"imageAttributionStyles":_vm.imageAttributionStyles}})],1):_vm._e()])]:_c('div',{staticClass:"image-info-field-label"},[_c('div',[_vm._v(" "+_vm._s(field.label)+" ")]),(_vm.getInpaintingMask(field))?_c(VBtnToggle,{attrs:{"rounded":"","dense":""},model:{value:(_vm.showMask),callback:function ($$v) {_vm.showMask=$$v},expression:"showMask"}},[_c(VBtn,{attrs:{"small":"","value":true}},[_c(VIcon,{staticStyle:{"font-size":"18px","margin":"0 6px 0 0"},style:({ color: _vm.showMask ? 'var(--grey-100)' : 'var(--grey-600)' })},[_vm._v("mdi-eye-outline")]),_c('span',{style:({ color: _vm.showMask ? 'var(--grey-100)' : 'var(--grey-600)' })},[_vm._v("Show mask")])],1),_c(VBtn,{attrs:{"small":"","value":false}},[_c(VIcon,{staticStyle:{"font-size":"18px","margin":"0 6px 0 0"},style:({ color: !_vm.showMask ? 'var(--grey-100)' : 'var(--grey-600)' })},[_vm._v("mdi-eye-off-outline")]),_c('span',{style:({ color: !_vm.showMask ? 'var(--grey-100)' : 'var(--grey-600)' })},[_vm._v("Hide mask")])],1)],1):_vm._e()],1),(field.type === _vm.RUNNIT_NODE_FIELD_TYPE.CHECKPOINT)?_c('runnit-model-selection'):_c('div',[_c('div',{staticClass:"image-info-field-value"},[((
                            field.type === _vm.RUNNIT_NODE_FIELD_TYPE.IMG &&
                            _vm.nodeRun.inputs[field.fieldDefUuid] &&
                            (_vm.nodeRun.inputs[field.fieldDefUuid].nodeRunResult || _vm.nodeRun.inputs[field.fieldDefUuid].upload)
                        ))?_c('div',{staticClass:"image-info-field-value-img"},[(_vm.nodeRun.inputs[field.fieldDefUuid].nodeRunResult)?_c('runnit-image',{attrs:{"nodeRunResult":_vm.nodeRun.inputs[field.fieldDefUuid].nodeRunResult,"width":"120px","is-inside-info-carousel":""}}):(_vm.nodeRun.inputs[field.fieldDefUuid].upload)?_c('runnit-image',{attrs:{"upload":_vm.nodeRun.inputs[field.fieldDefUuid].upload,"width":"120px","is-inside-info-carousel":""}}):_vm._e()],1):((
                            field.type === _vm.RUNNIT_NODE_FIELD_TYPE.IMG &&
                            _vm.nodeRun.inputs[field.fieldDefUuid] &&
                            _vm.nodeRun.inputs[field.fieldDefUuid].image &&
                            (_vm.nodeRun.inputs[field.fieldDefUuid].image.nodeRunResult || _vm.nodeRun.inputs[field.fieldDefUuid].image.upload)
                        ))?_c('div',{staticClass:"image-info-field-value-img"},[(_vm.nodeRun.inputs[field.fieldDefUuid].image.nodeRunResult)?_c('runnit-image',{attrs:{"nodeRunResult":_vm.nodeRun.inputs[field.fieldDefUuid].image.nodeRunResult,"width":"120px"}}):(_vm.nodeRun.inputs[field.fieldDefUuid].image.upload)?_c('runnit-image',{attrs:{"upload":_vm.nodeRun.inputs[field.fieldDefUuid].image.upload,"width":"120px"}}):_vm._e(),(_vm.getInpaintingMask(field))?_c('image-info-mask-preview',{attrs:{"mask":_vm.getInpaintingMask(field),"width":"120px","show-mask":_vm.showMask}}):_vm._e()],1):((
                        field.type === _vm.RUNNIT_NODE_FIELD_TYPE.WIDTH_HEIGHT &&
                        _vm.nodeRun.inputs[field.fieldDefUuid] &&
                        (_vm.nodeRun.inputs[field.fieldDefUuid].width || _vm.nodeRun.inputs[field.fieldDefUuid].height)
                    ))?_c('span',[_vm._v(" "+_vm._s(_vm.nodeRun.inputs[field.fieldDefUuid].width)+" x "+_vm._s(_vm.nodeRun.inputs[field.fieldDefUuid].height)+" ")]):(!_vm._isNil(_vm.nodeRun.inputs[field.fieldDefUuid]))?_c('span',[_vm._v(" "+_vm._s(_vm._truncate(_vm.nodeRun.inputs[field.fieldDefUuid], { length: _vm.fieldValueTruncateCharCount }))+" ")]):(field.__rgroup)?void 0:_c('span',{staticClass:"image-info-field-value-empty"},[_vm._v(" none ")])],2)])],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }