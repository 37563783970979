import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"runnits-pages-router"},[_c(VContainer,{staticClass:"py-0 fill-height",class:{
			'px-0': _vm.shouldRemoveSideMargins,
			'fixed-width-container': !_vm.shouldNotConstrainWidth,
		},attrs:{"fluid":""}},[_c(VRow,{staticClass:"fill-height my-0",class:{
				'mx-0': _vm.shouldRemoveSideMargins,
			}},[_c(VCol,{staticClass:"py-0 fill-height",class:{
					'px-0': _vm.shouldRemoveSideMargins,
				},attrs:{"cols":"12"}},[_c('router-view',{staticClass:"fill-height"})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }