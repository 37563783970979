
export default {
	name: 'WindowStepper',
	props: {
		small: { type: Boolean, default: false },
		arrowsOnly: { type: Boolean, default: false },
		containerClasses: { type: String, default: 'full-width full-height d-flex justify-space-between align-center' },
		containerStyles: { type: [Object, String], default: null },
		itemGroupStyles: { type: [Object, String], default: null },
		finishBtnText: { type: String, default: 'Finish' },
		showFinishButton: { type: Boolean, default: false },
		windowViewLength: { type: Number, required: true },
		largestWindowView: { type: Number, required: true },
		continueDisabled: { type: Boolean, default: false },
		finishDisabled: { type: Boolean, default: false },
		backDisabled: { type: Boolean, default: false },
		hideStepIconBtns: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
		value: { type: Number, required: true },
		allowNonLinearStepping: { type: Boolean, default: false },
	},
	methods: {
		handleStepClick(stepIndex, toggle) {
			if (this.allowNonLinearStepping) {
				this.$emit('step-clicked', stepIndex);
			}
			if (this.$vuetify.breakpoint.smAndUp) {
				toggle();
			}
		},
	},
};
