
import Vue from 'vue';

export default Vue.extend({
	name: 'ComplexBackgroundOfferingCard',
	props: {
		includeLeftBlobs: { type: Boolean, default: false },
		top: { type: String, default: '260px' },
		left: { type: String, default: '130px' },
		filter: { type: String, default: 'blur(60px)' },
	},
	data () {
		return {};
	},
});
